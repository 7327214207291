// services/playerService.ts

import axiosInstance from '../utils/axiosConfig'; // Import the axios instance for making HTTP requests
import { Player } from '../types'; // Import the Player type for type safety in responses

// Function to fetch player details by session ID
// Accepts a session ID as a parameter and returns a Player object
export const fetchPlayerBySession = async (
  sessionID: string,
): Promise<Player> => {
  // Make a GET request to the backend API to retrieve player details based on session ID
  const response = await axiosInstance.get<Player>(`/api/session/${sessionID}`);
  // Return the Player object from the response's data
  return response.data;
};

// Function to fetch the current player details by player ID
// Accepts a player ID as a parameter and returns a Player object
export const fetchPlayerById = async (playerId: string): Promise<Player> => {
  // Make a GET request to the backend API to retrieve player details by player ID
  const response = await axiosInstance.get<Player>(`/api/player/${playerId}`);
  // Return the Player object from the response's data
  return response.data;
};

// Function to fetch all players in a room by room code
export const fetchRoomPlayers = async (roomCode: string): Promise<Player[]> => {
  const response = await axiosInstance.get<Player[]>(
    `/api/room/${roomCode}/roomPlayers`,
  );
  return response.data;
};
