import React, { useEffect, useState } from 'react';
import { loadGameComponent } from '../utils/loadGame';
import { GameComponentProps, RoomDetails } from '../types';
import { usePlayerInfoContext } from '../contexts/PlayerInfoContext';
import { useRoomDetails } from '../contexts/RoomDetailsContext';
import { useRedirectOnEndGameEvent } from '../utils/endGameNav';
// import './Game.css'; //why am I not using this? go back and see if I need it

const Game: React.FC = () => {
  const { roomDetails } = useRoomDetails();
  const [GameComponent, setGameComponent] =
    useState<React.ComponentType<GameComponentProps> | null>(null);

  // End Game redirect
  useRedirectOnEndGameEvent(false); // false since it's the host screen

  // Load the appropriate game component whenever gameId in roomDetails changes
  useEffect(() => {
    const loadGameContent = async () => {
      if (roomDetails?.gameId) {
        const LoadedGameContent = await loadGameComponent(roomDetails.gameId);
        if (LoadedGameContent) {
          setGameComponent(() => LoadedGameContent);
        }
      }
    };

    loadGameContent();
  }, [roomDetails?.gameId]);

  // Show loading message until roomDetails and GameComponent are available
  if (!roomDetails || !GameComponent) {
    return <div>Loading room details...</div>;
  }

  // Directly render ContentWithPlayerContext, passing GameComponent and roomDetails
  return (
    <ContentWithPlayerContext
      GameComponent={GameComponent}
      roomDetails={roomDetails}
    />
  );
};

// Component to inject player context data into the loaded GameComponent
interface ContentWithPlayerContextProps {
  GameComponent: React.ComponentType<GameComponentProps>;
  roomDetails: RoomDetails;
}

const ContentWithPlayerContext: React.FC<ContentWithPlayerContextProps> = ({
  GameComponent,
  roomDetails,
}) => {
  const { isMyPlayerTurn, myPlayer, currentPlayer } = usePlayerInfoContext();

  // Prepare props for GameComponent using data from contexts
  const gameProps: GameComponentProps = {
    isPlayingScreen: false,
    roomDetails,
    isMyPlayerTurn,
    myPlayer,
    currentPlayer,
  };

  return (
    <div className="game-layout">
      <GameComponent {...gameProps} />
    </div>
  );
};

export default Game;
