import { useRef, useCallback } from 'react';

interface UseSpeechStreamResult {
  playStream: (speechStream: ReadableStream<Uint8Array>) => Promise<void>;
  speechRef: React.RefObject<HTMLAudioElement>;
  cleanup: () => void; // Exposed cleanup method
}

/**
 * Custom hook for managing audio streaming with centralized mute/unmute controls.
 */
const useSpeechStream = (): UseSpeechStreamResult => {
  // const { isMuted } = useAudioControl(); // Use centralized mute state
  const speechRef = useRef<HTMLAudioElement>(null); // Ref to the speech audio element
  const mediaSourceRef = useRef<MediaSource | null>(null);
  const sourceBufferRef = useRef<SourceBuffer | null>(null);
  const isPlaying = useRef(false); // Track if audio is currently playing
  const playbackQueue = useRef<ReadableStream<Uint8Array>[]>([]); // Queue for audio streams

  // Reference to the `sourceopen` event listener to ensure proper removal
  const sourceOpenListenerRef = useRef<() => void>();

  /**
   * Cleanup MediaSource and related resources.
   */
  const cleanup = useCallback(() => {
    const audioElement = speechRef.current;
    if (audioElement) {
      audioElement.src = ''; // Clear the audio element's source
    }

    if (mediaSourceRef.current) {
      if (sourceOpenListenerRef.current) {
        mediaSourceRef.current.removeEventListener(
          'sourceopen',
          sourceOpenListenerRef.current,
        );
      }
      mediaSourceRef.current = null;
    }

    sourceBufferRef.current = null;
    isPlaying.current = false; // Reset playback state
  }, []);

  /**
   * Plays an audio stream using MediaSource.
   *
   * @param speechStream - The ReadableStream of audio data.
   */
  const playStream = useCallback(
    async (speechStream: ReadableStream<Uint8Array>) => {
      if (isPlaying.current) {
        // If audio is currently playing, enqueue the new stream
        playbackQueue.current.push(speechStream);
        return;
      }

      if (!window.MediaSource) {
        console.error('MediaSource API is not supported in this browser.');
        return;
      }

      cleanup(); // Clean up any previous MediaSource before starting a new stream

      const mediaSource = new MediaSource();
      mediaSourceRef.current = mediaSource;

      const audioElement = speechRef.current;
      if (!audioElement) {
        console.error('Audio element not found.');
        return;
      }

      audioElement.src = URL.createObjectURL(mediaSource);
      // audioElement.muted = isMuted; // AudioControl component now handling mute state centrally

      isPlaying.current = true; // Mark audio as currently playing

      // Define the `sourceopen` event listener
      const onSourceOpen = () => {
        try {
          const sourceBuffer = mediaSource.addSourceBuffer('audio/mpeg');
          sourceBufferRef.current = sourceBuffer;
          fetchSpeechStream(speechStream, sourceBuffer, mediaSource);
        } catch (err) {
          console.error('Error initializing SourceBuffer:', err);
          console.error('Failed to initialize audio playback.');
        }
      };

      // Save the listener reference and add it
      sourceOpenListenerRef.current = onSourceOpen;
      mediaSource.addEventListener('sourceopen', onSourceOpen);

      try {
        await audioElement.play();

        // Wait for playback to finish before handling the next stream in the queue
        await new Promise<void>((resolve) => {
          const handleEnded = () => {
            resolve(); // Resolve the promise when playback ends
            if (mediaSource.readyState === 'open') {
              mediaSource.endOfStream(); // Graceful finalization of MediaSource
            }
            audioElement.onended = null; // Clean up the event listener
          };

          audioElement.onended = handleEnded;
        });
      } catch (err) {
        console.error('Audio playback failed:', err);
      } finally {
        isPlaying.current = false; // Mark audio as finished

        // Play the next stream in the queue, if any
        if (playbackQueue.current.length > 0) {
          const nextStream = playbackQueue.current.shift();
          if (nextStream) playStream(nextStream);
        }
      }
    },
    [cleanup],
  );

  /**
   * Fetches and processes the audio stream, appending it to the SourceBuffer.
   *
   * @param body - The ReadableStream of audio data.
   * @param sourceBuffer - The SourceBuffer to append data to.
   * @param mediaSource - The MediaSource managing the SourceBuffer.
   */
  const fetchSpeechStream = async (
    body: ReadableStream<Uint8Array>,
    sourceBuffer: SourceBuffer,
    mediaSource: MediaSource,
  ) => {
    const reader = body.getReader();
    const queue: Uint8Array[] = [];
    let done = false;

    const appendNext = () => {
      if (queue.length > 0 && !sourceBuffer.updating) {
        const chunk = queue.shift();
        if (chunk) {
          try {
            sourceBuffer.appendBuffer(chunk);
          } catch (err) {
            console.error('Error appending buffer:', err);
            console.error('Error appending audio data.');
            if (mediaSource.readyState === 'open') {
              mediaSource.endOfStream('network');
            }
          }
        }
      }
    };

    sourceBuffer.addEventListener('updateend', appendNext);

    try {
      while (!done) {
        const { done: streamDone, value } = await reader.read();
        done = streamDone;
        if (value) {
          queue.push(value);
          appendNext();
        }
      }

      // Wait for the queue to be empty before ending the stream
      while (queue.length > 0 || sourceBuffer.updating) {
        await new Promise<void>((resolve) => {
          const check = () => {
            if (!sourceBuffer.updating && queue.length === 0) {
              resolve();
            } else {
              setTimeout(check, 50);
            }
          };
          check();
        });
      }

      if (mediaSource.readyState === 'open') {
        mediaSource.endOfStream();
      }
    } catch (err) {
      console.error('Error while reading stream:', err);
      if (mediaSource.readyState === 'open') {
        mediaSource.endOfStream('network');
      }
      console.error('An error occurred while streaming audio.');
    } finally {
      sourceBuffer.removeEventListener('updateend', appendNext);
    }
  };

  return {
    playStream,
    speechRef, // Expose the speechRef so it can be connected in the component
    cleanup, // Expose cleanup to allow manual cleanup if needed
  };
};

export default useSpeechStream;
