// src/components/GameGridContainer.tsx

import React from 'react';
import './GameGridContainer.css';

interface GameGridContainerProps {
  children: React.ReactNode;
  title: string;
}

const GameGridContainer: React.FC<GameGridContainerProps> = ({
  children,
  title,
}) => {
  return (
    <div className="game-grid-container">
      <h2 className="category-title">{title}</h2>
      <div className="game-grid-scrollable">{children}</div>
    </div>
  );
};

export default GameGridContainer;
