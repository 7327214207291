// UserContext.tsx

import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import axiosInstance from '../utils/axiosConfig'; // Axios instance with predefined config
import axios from 'axios'; // Import axios and AxiosError
import { User } from '../types'; // Import User type
import { useNavigate } from 'react-router-dom'; // For navigation after logout
import { useWebSocket } from './WebSocketContext'; // Import WebSocket functions
import { usePageVisibility } from '../utils/returnToPage'; // Import the hook

// Define the structure of the UserContext data
interface UserContextType {
  user: User | null; // Holds user data or null if not logged in
  setUser: React.Dispatch<React.SetStateAction<User | null>>; // Function to update user state
  loading: boolean; // Indicates if user data is still loading
  logout: () => Promise<void>; // Function to log the user out
}

// Create the UserContext with a default value of null
export const UserContext = createContext<UserContextType | null>(null);

// UserProvider component to wrap any component needing access to user data
export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<User | null>(null); // State to store user data
  const [loading, setLoading] = useState(true); // State to track loading status
  const { onUserUpdate, joinUserRoom } = useWebSocket(); // Get WebSocket functions from context
  const navigate = useNavigate(); // For redirecting after logout

  // Function to fetch user details
  const fetchUserDetails = useCallback(async () => {
    console.log('[UserContext] Fetching user details...');
    try {
      // Fetch user details from backend
      const response = await axiosInstance.get<User>('/api/me');
      setUser(response.data); // Set user data in state upon successful fetch
      console.log(
        '[UserContext] User details fetched successfully:',
        response.data,
      );
    } catch (error) {
      setUser(null); // Default action: clear user state on error

      if (axios.isAxiosError(error)) {
        const status = error.response?.status;
        const message = error.response?.data?.message;
        if (status === 403 || status === 401) {
          console.warn(
            `[UserContext] User is not authenticated (status: ${status}):`,
            message,
          );
        } else {
          console.error(
            `[UserContext] Error fetching user details (status: ${status}):`,
            error.message,
          );
        }
      } else {
        console.error(
          '[UserContext] Unknown error fetching user details:',
          error,
        );
      }
    } finally {
      setLoading(false); // Set loading to false after fetch attempt
    }
  }, []);

  // Fetch user details on component mount
  useEffect(() => {
    fetchUserDetails();
  }, [fetchUserDetails]);

  // Use the custom hook to refresh user details when the page becomes visible
  usePageVisibility(fetchUserDetails);

  // Join user-specific WebSocket room when user data is available
  useEffect(() => {
    if (!user) {
      console.log('[UserContext] Skipping joinUserRoom because user is null');
      return;
    }
    joinUserRoom(user._id); // Join the room specific to the user's ID
    console.log(`[UserContext] Joined WebSocket room for user: ${user._id}`);
  }, [user, joinUserRoom]);

  // Listen for user updates via WebSocket and refresh user details
  useEffect(() => {
    const handleUserUpdate = (updatedUser: User) => {
      console.log(
        '[UserContext] User update received via WebSocket:',
        updatedUser,
      );
      setUser(updatedUser);
    };

    const unsubscribe = onUserUpdate(handleUserUpdate);

    return () => unsubscribe();
  }, [onUserUpdate]);

  // Logout function to log the user out and clear user state
  const logout = async () => {
    try {
      await axiosInstance.post('/api/logout'); // Call backend to handle logout
      setUser(null); // Clear user data on logout
      navigate('/'); // Redirect to homepage after logout
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error during logout:', error.message);
      } else {
        console.error('Unknown error during logout:', error);
      }
    }
  };

  return (
    <UserContext.Provider value={{ user, setUser, loading, logout }}>
      {children} {/* Render children within the context provider */}
    </UserContext.Provider>
  );
};

// Custom hook for easy access to UserContext data
export const useUserContext = () => {
  const context = useContext(UserContext); // Retrieve UserContext data

  if (!context) {
    throw new Error('useUserContext must be used within a UserProvider');
  }

  return context;
};
