// services/roomService.ts

import axiosInstance from '../utils/axiosConfig'; // Import the axios instance configured with base settings (like base URL and headers)
import { RoomDetails } from '../types'; // Import RoomDetails type for type safety

// Function to fetch room details based on a specific room code
// This function sends a GET request to the backend API to retrieve room details
export const fetchRoomDetails = async (
  roomCode: string,
): Promise<RoomDetails> => {
  // Make a GET request to the API endpoint, passing in the room code as a URL parameter
  const response = await axiosInstance.get<RoomDetails>(
    `/api/details/${roomCode}`,
  );
  // Return the data from the API response, which contains the room details
  return response.data;
};

// Function to update room details with a given payload
// This function sends a POST request with the payload to update specific fields of the room details
export const updateRoomDetails = async (
  roomCode: string,
  payload: object,
): Promise<RoomDetails> => {
  // Make a POST request to the API endpoint with the room code and payload
  // The payload specifies the fields to be updated (e.g., using $set or $inc operations)
  const response = await axiosInstance.post<RoomDetails>(
    `/api/update/${roomCode}`,
    payload,
  );
  // Return the updated room data from the API response
  return response.data;
};
