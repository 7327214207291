import axiosInstance from '../utils/axiosConfig';

// Track the last request and its result to prevent duplicate requests
let lastRequested: {
  key: string;
  timestamp: number;
  result: string | null;
} | null = null;

const createHostCommentary = async ({
  roomCode,
  language = 'en',
  hostAccent = 'en-US',
  hostStyle = 'standard',
  contentStyle = 'standard',
  gameNotes,
}: {
  roomCode: string;
  language?: string;
  hostAccent?: string;
  hostStyle?: string;
  contentStyle?: string;
  gameNotes: string;
}): Promise<string> => {
  // Create a unique key for the current request based on roomCode and gameNotes
  const key = `${roomCode}-${gameNotes}`;

  // Check if the last request matches the current one to avoid duplicates
  if (lastRequested && lastRequested.key === key) {
    console.log(`[DEBUG] Skipping duplicate request for key: ${key}`);
    if (lastRequested.result) {
      console.log('[DEBUG] Returning cached result:', lastRequested.result);
    }
    // Return the cached result if available
    return Promise.resolve(lastRequested.result || '');
  }

  // Update lastRequested with the new request details
  lastRequested = { key, timestamp: Date.now(), result: null };

  try {
    console.log('[INFO] Fetching host commentary...');

    // Make a POST request to the backend API to generate host commentary
    const response = await axiosInstance.post('/api/host-comments', {
      roomCode,
      language,
      hostAccent,
      hostStyle,
      contentStyle,
      gameNotes,
    });

    // Optional delay to simulate processing time or handle rate limiting
    await new Promise((resolve) => setTimeout(resolve, 2000));

    console.log('[DEBUG] Raw Axios response:', response);
    console.log('[DEBUG] Axios response.data:', response.data);

    // Access the 'generatedTexts' array from the response data
    const commentaryArray = response.data?.generatedTexts;

    // Validate that 'generatedTexts' exists, is an array, and is not empty
    if (
      !commentaryArray ||
      !Array.isArray(commentaryArray) ||
      commentaryArray.length === 0
    ) {
      throw new Error(
        'Invalid response format: Commentary array is missing or empty',
      );
    }

    // Extract the 'inputText' from the first element of the 'generatedTexts' array
    const hostCommentaryText = commentaryArray[0]?.inputText || '';
    if (!hostCommentaryText) {
      throw new Error('No commentary text found in the response');
    }

    console.log('[INFO] Fetched commentary:', hostCommentaryText);

    // Cache the result for future duplicate requests
    lastRequested.result = hostCommentaryText;

    // Return the fetched host commentary text
    return hostCommentaryText;
  } catch (error) {
    console.error('[ERROR] Failed to fetch host commentary:', error);
    // Reset the cache on error to allow retrying the request later
    lastRequested.result = null;
    // Re-throw the error to propagate it to the caller
    throw error;
  }
};

export default createHostCommentary;
