import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../Button';
import './StripeCompleted.css';

const StripeCompleted: React.FC = () => {
  const navigate = useNavigate(); // Hook for programmatic navigation

  return (
    <div className="onboard-completed">
      <div className="form-header-explainer">
        <div className="form-header">Thank you for subscribing!</div>
        <div className="form-explainer">
          Your payment has been completed and your receipt emailed to you
        </div>
      </div>
      <Button
        onClick={() => navigate('/game-library')} // Redirect to game library
        label="Go To Game Library"
      />
    </div>
  );
};

export default StripeCompleted;
