import React, { ReactNode } from 'react';
import PlayerCard from './PlayerCard';
import './PlayerContainer.css';
import { Player } from '../types'; // Ensure the Player type is imported from the correct location

interface PlayerContainerProps {
  players: Player[]; // Array of players with full details
  layoutType: 'grid' | 'single-row'; // Layout type for the container
  avatarSize: number; // Size of the avatar in pixels
  customContent?: (player: Player) => ReactNode; // Optional custom content to render for each player
}

const PlayerContainer: React.FC<PlayerContainerProps> = ({
  players,
  layoutType,
  avatarSize,
  customContent,
}) => {
  // Set dynamic grid columns based on layout type
  const gridColumns =
    layoutType === 'grid'
      ? `repeat(${Math.ceil(Math.sqrt(players.length))}, 1fr)`
      : `repeat(${players.length}, 1fr)`;

  return (
    <div
      className={`players-container ${layoutType}`}
      style={{ gridTemplateColumns: gridColumns }}
    >
      {players.map((player) => (
        <div key={player._id} className="player-card-with-custom-content">
          {customContent && (
            <div className="player-card-container-custom-content">
              {customContent(player)}
            </div>
          )}
          <PlayerCard player={player} avatarSize={avatarSize} />
        </div>
      ))}
    </div>
  );
};

export default PlayerContainer;
