// ColorSelector.tsx
import React, { useState, useEffect } from 'react';
import './ColorSelector.css';
import { colorOptions } from '../utils/colors';

interface ColorSelectorProps {
  onColorSelect: (color: string) => void;
}

const ColorSelector: React.FC<ColorSelectorProps> = ({ onColorSelect }) => {
  const [selectedColor, setSelectedColor] = useState<string>(colorOptions[0]);

  useEffect(() => {
    onColorSelect(selectedColor);
  }, [selectedColor, onColorSelect]);

  return (
    <div className="color-grid-container">
      {colorOptions.map((color) => (
        <div
          key={color}
          className={`color-circle ${selectedColor === color ? 'color-circle-selected' : ''}`}
          style={{ backgroundColor: color }}
          onClick={() => setSelectedColor(color)}
        />
      ))}
    </div>
  );
};

export default ColorSelector;
