import React, {
  createContext,
  useContext,
  useRef,
  useState,
  useCallback,
} from 'react';
import useSpeechStream from '../utils/useSpeechStream';
import { streamSpeech } from '../services/speechService';

interface SpeechContextProps {
  queueSpeech: (text: string, hostAccent?: string) => void; // Add optional hostAccent
  resetLastSpoken: () => void;
  speechRef: React.RefObject<HTMLAudioElement>; // Single reference for speech audio
  isSpeaking: boolean;
}

const SpeechContext = createContext<SpeechContextProps | undefined>(undefined);

export const SpeechProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { playStream, speechRef } = useSpeechStream(); // Speech-specific audioRef
  const speechQueue = useRef<Array<{ text: string; hostAccent?: string }>>([]);
  const lastSpoken = useRef<string | null>(null);
  const isSpeakingRef = useRef(false); // Ref for immediate updates
  const [isSpeaking, setIsSpeaking] = useState(false); // State for UI updates

  const processQueue = useCallback(async () => {
    if (isSpeakingRef.current || speechQueue.current.length === 0) return;

    while (speechQueue.current.length > 0) {
      const nextSpeech = speechQueue.current.shift();
      if (nextSpeech) {
        const { text, hostAccent } = nextSpeech;
        isSpeakingRef.current = true;
        setIsSpeaking(true);

        try {
          const speechStream = await streamSpeech({
            texts: [text],
            hostAccent: hostAccent || 'en-us', // Use 'default' if not provided
          });
          console.log(`[DEBUG] Speech playing: "${text}"`);
          await playStream(speechStream);
        } catch (err) {
          console.error(`[ERROR] Speech playback failed for: "${text}"`, err);
        } finally {
          console.log(`[DEBUG] Finished playing speech: "${text}"`);
          isSpeakingRef.current = false;
          setIsSpeaking(false);
        }
      }
    }
  }, [playStream]);

  const queueSpeech = useCallback(
    (text: string, hostAccent?: string) => {
      if (lastSpoken.current === text) {
        console.log(`[DEBUG] Skipping consecutive duplicate text: "${text}"`);
        return;
      }

      lastSpoken.current = text;
      console.log(`[DEBUG] Adding to speechQueue: "${text}"`);
      speechQueue.current.push({ text, hostAccent }); // Add text and hostAccent to the queue
      if (!isSpeakingRef.current) {
        processQueue(); // Only start processing if not already speaking
      }
    },
    [processQueue],
  );

  const resetLastSpoken = useCallback(() => {
    lastSpoken.current = null;
  }, []);

  return (
    <SpeechContext.Provider
      value={{
        queueSpeech,
        resetLastSpoken,
        speechRef, // Expose single speechRef
        isSpeaking,
      }}
    >
      {children}
    </SpeechContext.Provider>
  );
};

export const useSpeech = (): SpeechContextProps => {
  const context = useContext(SpeechContext);
  if (!context) {
    throw new Error('useSpeech must be used within a SpeechProvider');
  }
  return context;
};
