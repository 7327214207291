import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import './HamburgerMenu.css';

const HamburgerMenu: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false); // Internal state to manage open/close
  const location = useLocation(); // Detect route changes
  const userContext = useContext(UserContext);

  // Close the menu on route change
  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  // Toggle the menu open/close state
  const toggleMenu = () => {
    setIsOpen((prev) => !prev);
  };

  if (!userContext) {
    return null;
  }
  const { user } = userContext;
  const isSubscriber = user?.subscriber ?? false;

  // Define menu items
  const menuItems = [
    { path: '/', label: 'Home' },
    {
      path: isSubscriber ? '/game-library' : '/email',
      label: isSubscriber ? 'Game Library' : 'Sign Up / Sign In',
    },
    { path: '/about', label: 'About' },
    { path: '/terms', label: 'Terms' },
  ];

  // Filter out the current page
  const filteredMenuItems = menuItems.filter(
    (item) => item.path !== location.pathname,
  );

  return (
    <>
      {/* Menu toggle button */}
      <div className="menu-controller" onClick={toggleMenu}>
        <img
          src={isOpen ? '/assets/xmark.png' : '/assets/hamburger.png'}
          alt={isOpen ? 'Close menu' : 'Open menu'}
        />
      </div>

      {/* Menu content */}
      {isOpen && (
        <div className={`hamburger-menu ${isOpen ? 'open' : 'closed'}`}>
          <div className="menu-backdrop" onClick={toggleMenu}></div>
          <div className="menu-content">
            <ul>
              {filteredMenuItems.map((item) => (
                <li key={item.path}>
                  <Link to={item.path} onClick={toggleMenu}>
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default HamburgerMenu;
