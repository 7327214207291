// src/components/GameGrid.tsx

import React from 'react';
import './GameGrid.css';
import GameCover from './GameCover';

interface GameGridProps {
  games: { _id: string; imagePath: string; name: string; isActive: boolean }[];
  onGameClick: (gameId: string) => void;
}

const GameGrid: React.FC<GameGridProps> = ({ games, onGameClick }) => {
  return (
    <div className="game-grid">
      {games.map((game) => (
        <GameCover
          key={game._id}
          imagePath={game.imagePath}
          name={game.name}
          isActive={game.isActive}
          onClick={() => onGameClick(game._id)}
        />
      ))}
    </div>
  );
};

export default GameGrid;
