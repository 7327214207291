import React, { useEffect, useState } from 'react';
import { loadGameComponent } from '../utils/loadGame';
import { GameComponentProps, RoomDetails } from '../types';
import { usePlayerInfoContext } from '../contexts/PlayerInfoContext';
import { useRoomDetails } from '../contexts/RoomDetailsContext';
import { useRedirectOnEndGameEvent } from '../utils/endGameNav';
import './Playing.css';

const Playing: React.FC = () => {
  const { roomDetails } = useRoomDetails();
  const [DynamicGameContent, setDynamicGameContent] =
    useState<React.ComponentType<GameComponentProps> | null>(null);

  // Eng Game Redirect
  useRedirectOnEndGameEvent(true); // true since it's the player screen

  // Load the appropriate game component based on roomDetails' gameId
  useEffect(() => {
    const loadGameContent = async () => {
      if (roomDetails?.gameId) {
        const LoadedGameContent = await loadGameComponent(roomDetails.gameId);
        if (LoadedGameContent) {
          setDynamicGameContent(() => LoadedGameContent);
        }
      }
    };

    loadGameContent();
  }, [roomDetails?.gameId]);

  // Show a loading message until both roomDetails and DynamicGameContent are available
  if (!roomDetails || !DynamicGameContent) {
    return (
      <div className="game-waiting-start-playing">
        Waiting for game to start...
      </div>
    );
  }

  // Render ContentWithPlayerContext with DynamicGameContent and roomDetails props
  return (
    <ContentWithPlayerContext
      DynamicGameContent={DynamicGameContent}
      roomDetails={roomDetails}
    />
  );
};

// Component to inject player context data into the loaded DynamicGameContent
interface ContentWithPlayerContextProps {
  DynamicGameContent: React.ComponentType<GameComponentProps>;
  roomDetails: RoomDetails;
}

const ContentWithPlayerContext: React.FC<ContentWithPlayerContextProps> = ({
  DynamicGameContent,
  roomDetails,
}) => {
  const { isMyPlayerTurn, myPlayer, currentPlayer } = usePlayerInfoContext();

  // Prepare props for GameComponent using data from contexts
  const gameProps: GameComponentProps = {
    isPlayingScreen: true,
    roomDetails,
    isMyPlayerTurn,
    myPlayer,
    currentPlayer,
  };

  return (
    <div className="playing-layout">
      <DynamicGameContent {...gameProps} />
    </div>
  );
};

export default Playing;
