import React, { useEffect, useRef } from 'react';
import { AudioMotionAnalyzer } from 'audiomotion-analyzer';
import './AudioControl.css';

interface AudioControlProps {
  isMuted: boolean;
  onToggleMute: () => void;
  speechRef: React.RefObject<HTMLAudioElement>;
}

const AudioControl: React.FC<AudioControlProps> = ({
  isMuted,
  onToggleMute,
  speechRef,
}) => {
  const analyzerContainerRef = useRef<HTMLDivElement>(null);
  const audioMotionRef = useRef<AudioMotionAnalyzer | null>(null);
  const audioContextRef = useRef<AudioContext | null>(null);
  const gainNodeRef = useRef<GainNode | null>(null); // GainNode for speaker control

  useEffect(() => {
    if (!speechRef.current || !analyzerContainerRef.current) return;

    try {
      // Explicitly define AudioContext type
      const AudioContextClass: typeof AudioContext | undefined =
        window.AudioContext ||
        (window as typeof window & { webkitAudioContext?: typeof AudioContext })
          .webkitAudioContext;

      if (!AudioContextClass) {
        throw new Error('Web Audio API is not supported in this browser');
      }

      // Initialize AudioContext safely
      if (!audioContextRef.current) {
        audioContextRef.current = new AudioContextClass();
      }

      const audioContext = audioContextRef.current;

      // Ensure the `HTMLAudioElement` is connected to the AudioContext
      const mediaElementSource = audioContext.createMediaElementSource(
        speechRef.current,
      );

      // Create or reuse the GainNode for controlling speaker volume
      if (!gainNodeRef.current) {
        gainNodeRef.current = audioContext.createGain();
      }

      // Connect the GainNode to the audio context destination (speakers)
      gainNodeRef.current.connect(audioContext.destination);

      // Ensure the mediaElementSource connects to both the GainNode (for speaker output)
      // and the AudioMotionAnalyzer (for visualization)
      mediaElementSource.connect(gainNodeRef.current); // For speaker output

      // Create the AudioMotionAnalyzer instance
      if (audioMotionRef.current) {
        audioMotionRef.current.destroy();
      }

      audioMotionRef.current = new AudioMotionAnalyzer(
        analyzerContainerRef.current,
        {
          audioCtx: audioContext,
          source: mediaElementSource,
          mode: 7,
          gradientLeft: 'prism',
          gradientRight: 'prism',
          smoothing: 0.5,
          showPeaks: false,
          reflexRatio: 0.5,
          reflexAlpha: 1,
          reflexBright: 1,
          radial: true,
          spinSpeed: 6,
          channelLayout: 'dual-combined',
          mirror: -1,
          showBgColor: false,
          radius: 0.5,
          showScaleX: false,
          showScaleY: false,
          fftSize: 2048, // Audio quality was degraded at higher levels
          connectSpeakers: false, // Analyzer should not affect speaker output
        },
      );

      // Register a custom gradient
      audioMotionRef.current.registerGradient('custom1', {
        bgColor: '#000000',
        colorStops: [
          { color: '#e6ff66', pos: 0 }, // Light green at the endges
          { color: '#d0ff00', pos: 0.4 }, // Brand green
          { color: '#68ff18', pos: 0.8 }, // Midway green color
          { color: '#00ff2f', pos: 1 }, // Success green at the center
        ],
      });

      // Register a custom gradient
      audioMotionRef.current.registerGradient('custom2', {
        bgColor: '#000000',
        colorStops: [
          { color: '#b3b3b3', pos: 0 }, // Lightest gray at the edges
          { color: '#828282', pos: 0.4 }, // Medium gray
          { color: '#414141', pos: 0.8 }, // Darkest gray
          { color: '#ff0050', pos: 1 }, // Failure red at the center
        ],
      });

      // After registering the gradient, update the options to use it (Default custom 1)
      audioMotionRef.current.setOptions({
        gradientLeft: 'custom1',
        gradientRight: 'custom1',
      });
    } catch (err) {
      console.error('[ERROR] AudioMotionAnalyzer setup failed:', err);
    }

    // Cleanup on unmount
    return () => {
      if (audioMotionRef.current) {
        audioMotionRef.current.destroy();
        audioMotionRef.current = null;
      }
    };
  }, [speechRef]);

  // Effect to update gradients and gain based on isMuted state
  useEffect(() => {
    if (audioMotionRef.current) {
      audioMotionRef.current.setOptions({
        gradientLeft: isMuted ? 'custom2' : 'custom1',
        gradientRight: isMuted ? 'custom2' : 'custom1',
      });
    }

    // Update the GainNode's gain value based on isMuted state
    if (gainNodeRef.current) {
      gainNodeRef.current.gain.setValueAtTime(isMuted ? 0 : 1, 0); // Explicitly set the gain
    }
  }, [isMuted]);

  return (
    <div className="audio-control">
      {/* Mute/Unmute Button */}
      <button onClick={onToggleMute} aria-label={isMuted ? 'Unmute' : 'Mute'}>
        <img
          src={isMuted ? '/assets/Muted.svg' : '/assets/Unmuted.svg'}
          alt={isMuted ? 'Muted' : 'Unmuted'}
        />
      </button>

      {/* Analyzer */}
      <div className="audio-analyzer" ref={analyzerContainerRef}>
        {' '}
      </div>

      {/* Hidden Audio Element for Playback */}
      <audio ref={speechRef} style={{ display: 'none' }} />
    </div>
  );
};

export default AudioControl;
