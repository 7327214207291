// src/components/GameCoverModal.tsx

import React from 'react';
import Form from './Form'; // Import the Form component
import './GameCoverModal.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import axiosInstance from '../utils/axiosConfig'; // Import your Axios instance
import { useUserContext } from '../contexts/UserContext';
import { useAudioControl } from '../contexts/AudioControlContext';

interface GameCoverModalProps {
  isOpen: boolean;
  onClose: () => void;
  game: {
    _id: string;
    name: string;
    imagePath: string;
    isActive: boolean;
    description: string;
    recommendedPlayers: string;
  } | null;
}

const GameCoverModal: React.FC<GameCoverModalProps> = ({
  isOpen,
  onClose,
  game,
}) => {
  const navigate = useNavigate(); // Initialize useNavigate
  const { user } = useUserContext(); // Get user from UserContext
  const userId = user?._id; // Get the user's id
  const roomSettings = { ...user?.userSettings }; // Take the user's settings for the room settings...
  delete roomSettings.onboardingSet; // ... except for onboardingSet
  const { simulateToggleMute } = useAudioControl(); // Simulate the toggle to initialize audio analysis

  if (!isOpen || !game) return null;

  // Select Game
  const handleSelectGame = async (
    inputValue: string,
    setError: (msg: string | null) => void,
  ) => {
    try {
      // Call handleSelectGame with the game's ID and user ID from UserContext
      if (!userId || !userId) {
        setError('User not found. Please log in again.');
        return;
      }

      const response = await axiosInstance.post('/api/create-room', {
        gameId: game._id,
        userId: userId,
        roomSettings: roomSettings,
      });

      const { roomCode } = response.data;

      navigate(`/room/${roomCode}`); // Navigate to the new room

      simulateToggleMute();

      onClose(); // Close the modal after selecting the game
    } catch (error) {
      console.error('Error creating room:', error);
      setError('Failed to create room. Please try again.');
    }
  };

  return (
    <div className="gamecover-modal" onClick={onClose}>
      <div className="modal-box" onClick={(e) => e.stopPropagation()}>
        <img className="game-cover-img" src={game.imagePath} alt={game.name} />
        <div className="text-content">
          <div>
            <div className="title">{game.name}</div>
            <div className="recommended-players">{game.recommendedPlayers}</div>
            <div className="game-description">{game.description}</div>
          </div>

          <Form
            buttonLabel="Select Game"
            onSubmit={handleSelectGame}
            transparentButtonLabel="Back to Game Library"
            onTransparentButtonClick={onClose}
          />
        </div>
      </div>
    </div>
  );
};

export default GameCoverModal;
