import axios from 'axios';
import { getBaseURL } from './getBaseURL';

// Get the base URL using the utility function
const baseURL = getBaseURL();

// Create an Axios instance with the base URL and default headers
const axiosInstance = axios.create({
  baseURL,
  timeout: 10000, // Set to 10s
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

export default axiosInstance;
