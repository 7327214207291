import React from 'react';
import './ChoiceList.css';

interface ChoiceListProps {
  choices: string[];
  selectedChoice?: string | null; // selectedChoice can be string, null, or undefined
  onSelectChoice: (choice: string) => void;
  disabledChoices?: string[]; // Optional array of disabled choices
}

// The component remains reusable and decoupled from GameComponentProps
const ChoiceList: React.FC<ChoiceListProps> = ({
  choices,
  selectedChoice,
  onSelectChoice,
  disabledChoices = [], // Defaults to an empty array if not provided
}) => {
  return (
    <div className="choice-list">
      {choices.map((choice, index) => {
        const isDisabled = disabledChoices.includes(choice);
        return (
          <div
            key={index}
            onClick={() => !isDisabled && onSelectChoice(choice)}
            className={`choice-option ${selectedChoice === choice ? 'selected' : ''} ${isDisabled ? 'disabled' : ''}`}
          >
            {choice}
          </div>
        );
      })}
    </div>
  );
};

export default ChoiceList;
