// src/contexts/RoomDetailsContext.tsx
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { fetchRoomDetails as fetchRoomDetailsApi } from '../services/roomService';
import axiosInstance from '../utils/axiosConfig';
import { RoomDetails, RoomUpdatePayload } from '../types';
import { useWebSocket } from './WebSocketContext';
import { usePageVisibility } from '../utils/returnToPage'; // Import the hook

const RoomDetailsContext = createContext<
  | {
      roomDetails: RoomDetails | null;
      fetchRoomDetails: (roomCode: string) => Promise<void>;
      updateRoom: (payload: RoomUpdatePayload) => Promise<void>;
    }
  | undefined
>(undefined);

interface RoomDetailsProviderProps {
  children: React.ReactNode;
  roomCode: string;
  initialRoomData?: RoomDetails;
}

export const RoomDetailsProvider: React.FC<RoomDetailsProviderProps> = ({
  children,
  roomCode,
  initialRoomData = null,
}) => {
  const [roomDetails, setRoomDetails] = useState<RoomDetails | null>(
    initialRoomData,
  );
  const { joinGameRoom, onRoomUpdate, onRoomCreated } = useWebSocket();

  // Fetch room details only if no initial data is provided
  const fetchRoomDetails = useCallback(
    async (code: string = roomCode) => {
      if (initialRoomData) {
        console.log(
          '[DEBUG] Initial room data provided, skipping fetch:',
          initialRoomData,
        );
        return; // Skip fetch if we have initial data
      }

      try {
        const data = await fetchRoomDetailsApi(code);
        setRoomDetails(data);
        console.log('[DEBUG] Room details fetched:', data);
      } catch (error) {
        console.error('[ERROR] Failed to fetch room details:', error);
        setRoomDetails(null);
      }
    },
    [initialRoomData, roomCode],
  );

  // Use the custom hook to refresh room details when the page becomes visible
  usePageVisibility(fetchRoomDetails);

  // Effect to join the WebSocket room and fetch room details
  useEffect(() => {
    joinGameRoom(roomCode); // Join WebSocket room immediately

    if (!initialRoomData) {
      fetchRoomDetails(roomCode); // Fetch room details on mount if no initial data
    }
  }, [roomCode, joinGameRoom, initialRoomData, fetchRoomDetails]);

  // Handle WebSocket updates for room data
  useEffect(() => {
    const handleRoomUpdate = (updatedRoom: RoomDetails) => {
      console.log('[WebSocket] Room update received:', updatedRoom);
      setRoomDetails((prevRoomDetails) => {
        if (JSON.stringify(prevRoomDetails) !== JSON.stringify(updatedRoom)) {
          console.log('[WebSocket] Updating room details to:', updatedRoom);
          return updatedRoom;
        }
        return prevRoomDetails;
      });
    };

    const handleRoomCreated = (newRoom: RoomDetails) => {
      if (newRoom.roomCode === roomCode) {
        console.log('[WebSocket] Room created event received:', newRoom);
        setRoomDetails(newRoom);
      }
    };

    // Register the WebSocket listeners and return cleanup functions
    const cleanupUpdate = onRoomUpdate(handleRoomUpdate);
    const cleanupCreated = onRoomCreated(handleRoomCreated);

    return () => {
      cleanupUpdate();
      cleanupCreated();
    };
  }, [onRoomUpdate, onRoomCreated, roomCode]);

  // Update room with a structured payload
  const updateRoom = useCallback(
    async (payload: RoomUpdatePayload) => {
      try {
        await axiosInstance.post(`/api/update/${roomCode}`, payload);
        console.log('[DEBUG] Room update action executed successfully');
      } catch (error) {
        console.error('[ERROR] Failed to update room:', error);
      }
    },
    [roomCode],
  );

  // Memoize the context value to ensure reference stability
  const contextValue = useMemo(
    () => ({
      roomDetails,
      fetchRoomDetails,
      updateRoom,
    }),
    [roomDetails, fetchRoomDetails, updateRoom], // Dependencies
  );

  return (
    <RoomDetailsContext.Provider value={contextValue}>
      {children}
    </RoomDetailsContext.Provider>
  );
};

// Custom hook to access RoomDetailsContext in components
export const useRoomDetails = () => {
  const context = useContext(RoomDetailsContext);
  if (!context) {
    throw new Error('useRoomDetails must be used within a RoomDetailsProvider');
  }
  return context;
};
