// contexts/webSocketContext.tsx

import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import WebSocketService, {
  ReceiveEventPayloads,
} from '../services/webSocketService';
import { RoomDetails, Player, User } from '../types';

/**
 * Defines the possible connection statuses for the WebSocket.
 */
type ConnectionStatus =
  | 'connected'
  | 'disconnected'
  | 'connecting'
  | 'reconnecting'
  | 'failed';

/**
 * WebSocketContextType defines the structure of the context data.
 */
interface WebSocketContextType {
  joinGameRoom: (roomCode: string) => void; // Join a game room
  joinUserRoom: (userId: string) => void; // Join a user-specific room
  connectionStatus: ConnectionStatus; // WebSocket connection status
  resetConnection: () => void; // Reset WebSocket connection
  onRoomCreated: (callback: (newRoom: RoomDetails) => void) => () => void; // Listener for room creation
  onRoomUpdate: (callback: (updatedRoom: RoomDetails) => void) => () => void; // Listener for room updates
  onPlayerUpdate: (
    callback: (updatedPlayer: { playerId: string; player: Player }) => void,
  ) => () => void; // Listener for player updates
  onUserUpdate: (callback: (updatedUser: User) => void) => () => void; // Listener for user updates
  emitRedirectReplay: (roomCode: string) => void; // Emit replay redirection
  emitRedirectLibrary: (roomCode: string) => void; // Emit library redirection
  onRedirectReplay: (callback: () => void) => () => void; // Listener for replay redirection
  onRedirectLibrary: (callback: () => void) => () => void; // Listener for library redirection
}

/**
 * Create the WebSocket context with undefined as the default value.
 */
const WebSocketContext = createContext<WebSocketContextType | undefined>(
  undefined,
);

/**
 * Provider component for the WebSocket context.
 */
export const WebSocketProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [connectionStatus, setConnectionStatus] =
    useState<ConnectionStatus>('connecting');

  const webSocketServiceRef = useRef(WebSocketService.getInstance());
  const webSocketService = webSocketServiceRef.current;

  /**
   * Handle WebSocket connection events.
   */
  useEffect(() => {
    const handleConnect = () => {
      console.log('[WebSocketContext] Connected to WebSocket');
      setConnectionStatus('connected');
    };

    const handleDisconnect = (reason: string) => {
      console.warn(`[WebSocketContext] Disconnected: ${reason}`);
      setConnectionStatus('disconnected');
    };

    const handleReconnectAttempt = (attempt: number) => {
      console.log(`[WebSocketContext] Reconnection attempt #${attempt}`);
      setConnectionStatus('reconnecting');
    };

    const handleReconnectFailed = () => {
      console.error('[WebSocketContext] Reconnection failed');
      setConnectionStatus('failed');
    };

    webSocketService.socket.on('connect', handleConnect);
    webSocketService.socket.on('disconnect', handleDisconnect);
    webSocketService.socket.on('reconnect_attempt', handleReconnectAttempt);
    webSocketService.socket.on('reconnect_failed', handleReconnectFailed);

    return () => {
      webSocketService.socket.off('connect', handleConnect);
      webSocketService.socket.off('disconnect', handleDisconnect);
      webSocketService.socket.off('reconnect_attempt', handleReconnectAttempt);
      webSocketService.socket.off('reconnect_failed', handleReconnectFailed);
    };
  }, [webSocketService]);

  /**
   * Reset the WebSocket connection manually.
   */
  const resetConnection = useCallback(() => {
    console.log('[WebSocketContext] Resetting WebSocket connection...');
    webSocketService.socket.disconnect();
    webSocketService.socket.connect();
  }, [webSocketService]);

  /**
   * Join a game-specific WebSocket room.
   */
  const joinGameRoom = useCallback(
    (roomCode: string) => {
      webSocketService.joinGameRoom(roomCode);
      console.log(`[WebSocketContext] Joined game room: ${roomCode}`);
    },
    [webSocketService],
  );

  /**
   * Join a user-specific WebSocket room.
   */
  const joinUserRoom = useCallback(
    (userId: string) => {
      webSocketService.joinUserRoom(userId);
      console.log(`[WebSocketContext] Joined user room: ${userId}`);
    },
    [webSocketService],
  );

  /**
   * Creates a listener for a specific WebSocket event.
   * Returns a function to unregister the listener.
   */
  const createListener = useCallback(
    <K extends keyof ReceiveEventPayloads>(
      event: K,
      callback: (data: ReceiveEventPayloads[K]) => void,
    ) => {
      const wrappedCallback = (data: ReceiveEventPayloads[K]) => {
        try {
          callback(data);
        } catch (error) {
          console.error(
            `[WebSocketContext] Error in listener for ${event}:`,
            error,
          );
        }
      };

      webSocketService.onEvent(event, wrappedCallback);
      console.log(`[WebSocketContext] Registered listener for ${event}`);

      // Return a cleanup function to unregister the listener
      return () => {
        webSocketService.offEvent(event, wrappedCallback);
        console.log(`[WebSocketContext] Unregistered listener for ${event}`);
      };
    },
    [webSocketService],
  );

  /**
   * Listener for room creations.
   */
  const onRoomCreated = useCallback(
    (callback: (newRoom: RoomDetails) => void) =>
      createListener('roomCreated', callback),
    [createListener],
  );

  /**
   * Listener for room updates.
   */
  const onRoomUpdate = useCallback(
    (callback: (updatedRoom: RoomDetails) => void) =>
      createListener('roomUpdate', callback),
    [createListener],
  );

  /**
   * Listener for player updates.
   */
  const onPlayerUpdate = useCallback(
    (callback: (data: { playerId: string; player: Player }) => void) =>
      createListener('playerUpdate', callback),
    [createListener],
  );

  /**
   * Listener for user updates.
   */
  const onUserUpdate = useCallback(
    (callback: (updatedUser: User) => void) =>
      createListener('userUpdate', callback),
    [createListener],
  );

  /**
   * Emit 'redirectReplay' event to the server.
   */
  const emitRedirectReplay = useCallback(
    (roomCode: string) => {
      webSocketService.emitEvent('redirectReplay', { roomCode });
      console.log(
        `[WebSocketContext] Emitted 'redirectReplay' for room: ${roomCode}`,
      );
    },
    [webSocketService],
  );

  /**
   * Emit 'redirectLibrary' event to the server.
   */
  const emitRedirectLibrary = useCallback(
    (roomCode: string) => {
      webSocketService.emitEvent('redirectLibrary', { roomCode });
      console.log(
        `[WebSocketContext] Emitted 'redirectLibrary' for room: ${roomCode}`,
      );
    },
    [webSocketService],
  );

  /**
   * Listener for 'redirectReplay' event.
   */
  const onRedirectReplay = useCallback(
    (callback: () => void) => createListener('redirectReplay', callback),
    [createListener],
  );

  /**
   * Listener for 'redirectLibrary' event.
   */
  const onRedirectLibrary = useCallback(
    (callback: () => void) => createListener('redirectLibrary', callback),
    [createListener],
  );

  /**
   * Provide the context value.
   */
  const contextValue: WebSocketContextType = useMemo(
    () => ({
      joinGameRoom,
      joinUserRoom,
      connectionStatus,
      resetConnection,
      onRoomCreated,
      onRoomUpdate,
      onPlayerUpdate,
      onUserUpdate,
      emitRedirectReplay,
      emitRedirectLibrary,
      onRedirectReplay,
      onRedirectLibrary,
    }),
    [
      joinGameRoom,
      joinUserRoom,
      connectionStatus,
      resetConnection,
      onRoomCreated,
      onRoomUpdate,
      onPlayerUpdate,
      onUserUpdate,
      emitRedirectReplay,
      emitRedirectLibrary,
      onRedirectReplay,
      onRedirectLibrary,
    ],
  );

  return (
    <WebSocketContext.Provider value={contextValue}>
      {children}
    </WebSocketContext.Provider>
  );
};

/**
 * Custom hook to use the WebSocket context.
 */
export const useWebSocket = (): WebSocketContextType => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
};
