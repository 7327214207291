import React, { useState } from 'react';
import ChoiceList from '../ChoiceList';
import './ContentStyleSelection.css';
import axiosInstance from '../../utils/axiosConfig';
import { contentStyleOptions } from '../../utils/userSettingsOptions';

interface OnboardingProps {
  nextStep: () => void; // Prop to move to the next onboarding step
}

const ContentStyleSelection: React.FC<OnboardingProps> = ({ nextStep }) => {
  // State to keep track of the selected choice for UI feedback
  const [selectedChoice, setSelectedChoice] = useState<string | null>(null);

  // Separate choices into enabled and disabled lists
  const choices = contentStyleOptions.map((option) => option.label);
  const disabledChoices = contentStyleOptions
    .filter((option) => option.disabled)
    .map((option) => option.label);

  const handleContentStyleChoice = async (choice: string) => {
    const selectedOption = contentStyleOptions.find(
      (option) => option.label === choice,
    );

    if (!selectedOption) return;

    try {
      // Update the selected choice locally for immediate feedback
      setSelectedChoice(choice);

      // Send selected content style to the backend
      await axiosInstance.post('/api/update-user', {
        $set: {
          'userSettings.contentStyle': selectedOption.value, // Update contentStyle in userSettings
          'userSettings.onboardingSet': true, // Set onboardingSet to true because preferences all set
        },
      });

      console.log(`Updated content style to: ${selectedOption.value}`);
      // Call nextStep to move to the next onboarding step
      nextStep();
    } catch (error) {
      console.error('Failed to update content style:', error);
    }
  };

  return (
    <div className="onboard-pick-content-style">
      <div className="form-header-explainer">
        <div className="form-header"> Content Style </div>
        <div className="form-explainer">
          {' '}
          All of our game content is generated by AI. While we strive to tailor
          the content to align with your selected preferences, we cannot fully
          control the AI output.
        </div>
      </div>
      <ChoiceList
        choices={choices} // Passes the labels only
        selectedChoice={selectedChoice} // for UI feedback
        onSelectChoice={handleContentStyleChoice}
        disabledChoices={disabledChoices} // Passes only disabled choices
      />
    </div>
  );
};

export default ContentStyleSelection;
