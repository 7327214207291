import React from 'react';
import Avatar from './Avatar';
import './PlayerCard.css';
import { Player } from '../types'; // Import the Player type

interface PlayerCardProps {
  player: Player; // Use Player type for a consistent structure
  avatarSize: number; // Allow avatar size to be customized
  showName?: boolean; // Optional flag to show/hide the name
}

const PlayerCard: React.FC<PlayerCardProps> = ({
  player,
  avatarSize,
  showName = true, // Default to true if not specified
}) => {
  return (
    <div className="player-card">
      {/* Use the player's color and size for the avatar */}
      <Avatar color={player.avatarColor} size={avatarSize} />

      {/* Conditionally display the player’s name */}
      {showName && <h3 className="player-name">{player.playerName}</h3>}
    </div>
  );
};

export default PlayerCard;
