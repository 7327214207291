// src/services/speechService.ts

/**
 * Defines the structure of the payload sent to the backend for text-to-speech synthesis.
 */
export interface StreamSpeechPayload {
  texts: string[];
  hostAccent: string;
}

/**
 * Sends a POST request to the backend API to initiate streaming speech synthesis.
 *
 * @param payload - The text payload to be synthesized into speech.
 * @returns A ReadableStream of Uint8Array representing the audio data.
 * @throws An error if the request fails or the browser does not support MediaSource.
 */
export const streamSpeech = async (
  payload: StreamSpeechPayload,
): Promise<ReadableStream<Uint8Array>> => {
  try {
    // Dynamically resolve the base URL using an environment variable
    const baseUrl =
      process.env.REACT_APP_API_BASE_URL || 'http://localhost:5001';

    // Send POST request to the backend API
    const response = await fetch(`${baseUrl}/api/stream-speech`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    // Check if the response is successful
    if (!response.ok) {
      throw new Error(
        `Streaming audio failed: ${response.status} - ${response.statusText}`,
      );
    }

    // Verify that the browser supports the MediaSource API
    if (!window.MediaSource) {
      throw new Error('Audio streaming is not supported in this browser.');
    }

    // Ensure the response body exists
    if (!response.body) {
      throw new Error('No audio data received from the server.');
    }

    // Return the ReadableStream of audio data
    return response.body as ReadableStream<Uint8Array>;
  } catch (error) {
    // Enhance error handling for downstream use
    if (error instanceof Error) {
      throw new Error(`AudioService Error: ${error.message}`);
    } else {
      throw new Error('An unknown error occurred while streaming audio.');
    }
  }
};
