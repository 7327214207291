// Exported to ColorSelector.tsx, Player.tsx
export const colorOptions = [
  '#3f4cd2', // Darkest Purple-Blue
  '#6d4cff', // Dark Purple
  '#a84cff', // Purple
  '#d84cff', // Magenta-Purple
  '#ff4cde', // Magenta
  '#ff4c9c', // Pink Coral
  '#ff6c4c', // Orange
  '#ffc74c', // Bright Yellow-Orange
  '#ffe34c', // Pale Yellow
  '#8cff8a', // Light Green
  '#4cffc7', // Mint Green
  '#4cdeff', // Light Cyan
  '#4c9eff', // Sky Blue
  '#4c72ff', // Medium Blue
  '#3c6aa0', // Slate Blue
  '#268080', // Teal
  '#72a08f', // Muted Teal-Green
  '#a4b49a', // Sage Green
  '#e8c89f', // Light Beige
  '#dba66f', // Sandy Tan
  '#d2933f', // Golden Brown
  '#b87332', // Copper
  '#8b5229', // Dark Brown
];

export const primaryColors = [
  '#000000', // Background
  '#ffffff', // Primary text
  '#b3b3b3', // Secondary text Gray
  '#828282', // Disabled Gray
  '#414141', // Dark Gray for subtle elements
  '#d0ff00', // Primary green color
  '#00ff2f', // Success green color
  '#68ff18', // Midway green color between Primary and Success
  '#ff0050', // Failure red color
  '#00d0ff', // Hyperlink blue color
];
