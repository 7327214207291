// src/components/Wordmark.tsx

import React from 'react';
import { Link } from 'react-router-dom';
import './Wordmark.css';

const Wordmark: React.FC = () => {
  return (
    <div className="wordmark">
      <Link to="/" title="Go to Homepage">
        <img src="/assets/wordmark.png" alt="Airhead Games Logo" />
      </Link>
    </div>
  );
};

export default Wordmark;
