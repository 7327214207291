// userSettingsOptions.ts

// Define language options with associated accents
export const languageOptions = [
  {
    value: 'en',
    label: 'English',
    disabled: false,
    accents: [
      { value: 'en-us', voiceName: 'en-US-Journey-D', label: 'American' },
      { value: 'en-gb', voiceName: 'en-GB-Journey-D', label: 'British' },
      { value: 'en-in', voiceName: 'en-IN-Journey-D', label: 'Indian' },
      { value: 'en-au', voiceName: 'en-AU-Journey-D', label: 'Australian' },
    ],
  },
  {
    value: 'fr',
    label: 'Français (à venir)', // "Coming Soon"
    disabled: true,
    accents: [
      {
        value: 'fr-fr',
        voiceName: 'fr-FR-Journey-D',
        label: 'Français de France',
      },
      {
        value: 'fr-ca',
        voiceName: 'fr-CA-Journey-D',
        label: 'Français Canadien',
      },
    ],
  },
  {
    value: 'es',
    label: 'Español (próximamente)', // "Coming Soon"
    disabled: true,
    accents: [
      {
        value: 'es-es',
        voiceName: 'es-ES-Journey-D',
        label: 'Español de España',
      },
      {
        value: 'es-us',
        voiceName: 'es-US-Journey-D',
        label: 'Español de América',
      },
    ],
  },
  {
    value: 'de',
    label: 'Deutsch (bald verfügbar)', // "Coming Soon"
    disabled: true,
    accents: [{ value: 'de', voiceName: 'de-DE-Journey-D', label: 'Deutsch' }],
  },
  {
    value: 'it',
    label: 'Italiano (prossimamente)', // "Coming Soon"
    disabled: true,
    accents: [{ value: 'it', voiceName: 'it-IT-Journey-D', label: 'Italiano' }],
  },
];

// Utility function to count enabled languages and accents
export const getEnabledLanguageAndAccentCounts = () => {
  const languagesCount = languageOptions.filter(
    (language) => !language.disabled,
  ).length;

  const accentsCount = languageOptions
    .filter((language) => !language.disabled) // Only include enabled languages
    .reduce((count, language) => count + language.accents.length, 0);

  return {
    languagesCount,
    accentsCount,
  };
};

export const hostStyleOptions = [
  {
    label: 'Cheerleader, sweet & supportive',
    value: 'cheerleader',
    disabled: false,
  },
  {
    label: 'Entertainer, quirky & whimsical',
    value: 'entertainer',
    disabled: false,
  },
  { label: 'Comedian, snarky & witty', value: 'comedian', disabled: false },
  { label: 'Diva, dramatic & intense', value: 'diva', disabled: false },
  {
    label: 'Narrator, relaxed & no-nonsense',
    value: 'narrator',
    disabled: false,
  },
];

export const contentStyleOptions = [
  {
    label: 'Standard, for general audiences',
    value: 'standard',
    disabled: false,
  },
  {
    label: 'Safe, for family-friendly audiences',
    value: 'safe',
    disabled: false,
  },
  { label: 'Edgy, for mature audiences', value: 'edgy', disabled: false },
];
