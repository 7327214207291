import React, { useEffect, useState, useRef } from 'react';
import axiosInstance from '../utils/axiosConfig';
import Form from '../components/Form';
import { v4 as uuidv4 } from 'uuid'; // Import uuid to generate session ID
import './Player.css';
import { useRoomDetails } from '../contexts/RoomDetailsContext';
import { usePlayerInfoContext } from '../contexts/PlayerInfoContext';
import { colorOptions } from '../utils/colors';

const Player: React.FC = () => {
  const [selectedColor, setSelectedColor] = useState<string>('');
  const [roomCode, setRoomCode] = useState<string | null>(null);
  const avatarRef = useRef<HTMLObjectElement>(null);
  const colorGridContainerRef = useRef<HTMLDivElement>(null);
  const { roomDetails } = useRoomDetails(); // Access roomDetails from context
  const { myPlayer } = usePlayerInfoContext(); // Access myPlayer to set preferred name and color

  // Function to show game link if on screen and game has already started
  const handleGoToGame = () => {
    if (roomCode) {
      window.location.href = `/game/${roomCode}/playing`;
    }
  };

  // Function to set/get session ID from the cookies
  const getSessionID = (): string => {
    let sessionID = document.cookie
      .split('; ')
      .find((row) => row.startsWith('sessionID='))
      ?.split('=')[1];

    if (!sessionID) {
      sessionID = uuidv4();
      document.cookie = `sessionID=${sessionID}; path=/;`;
    }
    return sessionID;
  };

  // Function to dynamically set avatar color
  const setAvatarColor = (color: string) => {
    const svgDoc = avatarRef.current?.contentDocument;
    if (svgDoc) {
      const avatarPaths = svgDoc.querySelectorAll('path');
      avatarPaths.forEach((path) => {
        path.setAttribute('fill', color);
      });
    }
  };

  // Function to handle loading the avatar and applying color
  const handleAvatarLoad = () => {
    setAvatarColor(selectedColor);
  };

  // Function to validate room code
  const validateRoomCode = async (code: string) => {
    if (!code) {
      console.warn('No valid room code found, redirecting to homepage...');
      window.location.replace('/');
      return;
    }

    try {
      const response = await axiosInstance.get(
        `/api/validate-room?roomCode=${code}`,
      );
      if (!response.data.valid) {
        console.warn('Invalid room code, redirecting to homepage...');
        window.location.replace('/');
      }
    } catch (error) {
      console.error('Error validating room code:', error);
      window.location.replace('/');
    }
  };

  // Extract and validate room code
  useEffect(() => {
    const urlSegments = window.location.pathname.split('/').filter(Boolean);
    if (urlSegments.length >= 3 && urlSegments[0] === 'room') {
      const extractedRoomCode = urlSegments[1];
      setRoomCode(extractedRoomCode);
      validateRoomCode(extractedRoomCode);
    } else {
      console.warn('Room code not found in URL.');
    }
  }, []);

  // Set initial selected color based on myPlayer or random
  useEffect(() => {
    if (myPlayer?.avatarColor) {
      setSelectedColor(myPlayer.avatarColor);
    } else {
      const randomColor =
        colorOptions[Math.floor(Math.random() * colorOptions.length)];
      setSelectedColor(randomColor);
    }
  }, [myPlayer]);

  // Update avatar color and scroll to selected color when selectedColor changes
  useEffect(() => {
    setAvatarColor(selectedColor);

    // Scroll to the selected color and center it
    if (colorGridContainerRef.current) {
      const selectedColorElement = colorGridContainerRef.current.querySelector(
        `[data-color="${selectedColor}"]`,
      ) as HTMLElement | null; // Cast to HTMLElement

      if (selectedColorElement) {
        const container = colorGridContainerRef.current;
        const containerWidth = container.clientWidth;
        const selectedColorWidth = selectedColorElement.clientWidth;

        // Calculate the offset to center the selected color
        const selectedColorPosition = selectedColorElement.offsetLeft;
        const offset =
          selectedColorPosition - containerWidth / 2 + selectedColorWidth / 2;

        container.scrollTo({
          left: offset,
          behavior: 'smooth',
        });
      }
    }
  }, [selectedColor]);

  // Function to handle form submission
  const handleFormSubmit = async (playerName: string) => {
    if (!roomCode) {
      console.warn('Room code is not set.');
      return;
    }

    const sessionID = getSessionID();

    console.log('Sending POST request to /api/room/:roomCode/player with:', {
      playerName,
      avatarColor: selectedColor,
      sessionID,
    });

    try {
      const response = await axiosInstance.post(
        `/api/room/${roomCode}/player`,
        {
          playerName,
          avatarColor: selectedColor,
          lastJoined: new Date(),
          sessionID,
        },
      );

      console.log('Received response from POST request:', response.data);

      if (response.data.success) {
        // Redirect to the game page
        window.location.href = `/game/${roomCode}/playing`;
      } else {
        console.error('Error in response:', response.data.message);
      }
    } catch (error: unknown) {
      // Type guard for error handling
      if (error instanceof Error) {
        console.error('Error creating/updating player:', error.message);
      } else {
        console.error('Unexpected error:', error);
      }
    }
  };

  // Determine the button label based on activeGame
  const isGameActive = roomDetails?.activeGame;

  return (
    <div className="player-page-container">
      <div className="avatar-container">
        <object
          id="avatar"
          type="image/svg+xml"
          data="/assets/avatar-normal.svg"
          ref={avatarRef}
          className="avatar-image"
          onLoad={handleAvatarLoad}
        />
      </div>

      <div className="color-grid-container" ref={colorGridContainerRef}>
        {colorOptions.map((color) => (
          <div
            key={color}
            data-color={color}
            className={`color-circle ${
              color === selectedColor ? 'color-circle-selected' : ''
            }`}
            style={{ backgroundColor: color }}
            onClick={() => {
              setSelectedColor(color);
            }}
          ></div>
        ))}
      </div>
      <div className="player-page-form">
        <Form
          placeholder="Enter Name"
          buttonLabel={isGameActive ? 'Game in Progress' : 'Confirm Player'}
          onSubmit={handleFormSubmit}
          disabledButton={roomDetails?.activeGame}
          prepopulatedValue={myPlayer?.playerName}
        />

        {isGameActive && (
          <a onClick={handleGoToGame} className="go-to-game-link">
            Go To Game
          </a>
        )}
      </div>
    </div>
  );
};

export default Player;
