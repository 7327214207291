import React, { createContext, useContext, useState, ReactNode } from 'react';

interface AudioControlContextProps {
  isMuted: boolean;
  mute: () => void;
  unmute: () => void;
  simulateToggleMute: () => void; // Simulates user interaction
}

const AudioControlContext = createContext<AudioControlContextProps | undefined>(
  undefined,
);

export const AudioControlProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isMuted, setIsMuted] = useState(true);

  const mute = () => setIsMuted(true);
  const unmute = () => setIsMuted(false);

  const simulateToggleMute = () => {
    setIsMuted(false); // Unmute first
    setTimeout(() => setIsMuted(true), 100); // Remute after a short delay
    console.log(`[DEBUG] Simulated Toggling Unmute+Mute`);
  };

  return (
    <AudioControlContext.Provider
      value={{ isMuted, mute, unmute, simulateToggleMute }}
    >
      {children}
    </AudioControlContext.Provider>
  );
};

export const useAudioControl = () => {
  const context = useContext(AudioControlContext);
  if (!context) {
    throw new Error(
      'useAudioControl must be used within an AudioControlProvider',
    );
  }
  return context;
};
