// src/pages/GameLibrary.tsx

import React, { useEffect, useState } from 'react';
import axiosInstance from '../utils/axiosConfig';
import './GameLibrary.css';
import GameGridContainer from '../components/GameGridContainer';
import GameGrid from '../components/GameGrid';
import Wordmark from '../components/Wordmark';
import GameCoverModal from '../components/GameCoverModal';
import UserSettings from '../components/UserSettings';
import { Game } from '../types';

const GameLibrary: React.FC = () => {
  const [games, setGames] = useState<Game[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedGame, setSelectedGame] = useState<Game | null>(null);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false); // Track settings state

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const response = await axiosInstance.get<Game[]>(`/api/games`, {
          withCredentials: true,
        });

        // Convert _id to string for each game
        const fetchedGames = response.data.map((game: Game) => ({
          ...game,
          _id: game._id.toString(),
        }));

        setGames(fetchedGames);
      } catch (error) {
        console.error('Error fetching games:', error);
      }
    };

    fetchGames();
  }, []);

  const handleGameClick = (gameId: string) => {
    const game = games.find((g) => g._id === gameId);
    if (game) {
      if (!game.isActive) {
        alert('This game is not available yet!');
      } else {
        setSelectedGame(game);
        setIsModalOpen(true);
      }
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedGame(null);
  };

  const toggleSettings = () => {
    setIsSettingsOpen((prevState) => !prevState);
  };

  return (
    <div className="game-library-container">
      <Wordmark />
      <div className="settings-controller" onClick={toggleSettings}>
        {/* Dynamically update the image source */}
        <img
          src={isSettingsOpen ? '/assets/xmark.png' : '/assets/settings.png'}
          alt={isSettingsOpen ? 'Close settings' : 'Open settings'}
        />
      </div>
      <main>
        {isSettingsOpen ? (
          <UserSettings /> // Render UserSettings component when settings are open
        ) : (
          <GameGridContainer title="Games">
            <GameGrid games={games} onGameClick={handleGameClick} />
          </GameGridContainer>
        )}

        {/* Use the updated GameCoverModal */}
        <GameCoverModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          game={selectedGame}
        />
      </main>
    </div>
  );
};

export default GameLibrary;
