import React, { useContext } from 'react'; // Import React and useContext
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import './Email.css'; // Ensure this file contains styles specific to the Email page
import Form from '../components/Form';
import Wordmark from '../components/Wordmark';
import axiosInstance from '../utils/axiosConfig';
import { v4 as uuidv4 } from 'uuid'; // Import UUID for generating session ID
import { UserContext } from '../contexts/UserContext';
import HamburgerMenu from '../components/HamburgerMenu';

const Email: React.FC = () => {
  const navigate = useNavigate(); // Initialize navigate for navigation

  const userContext = useContext(UserContext);

  if (!userContext) {
    // Handle the case where UserContext is not available
    return null; // Or display an error message
  }

  const { user } = userContext;
  const isSubscriber = user?.subscriber ?? false;

  const getSessionID = (): string => {
    let sessionID = document.cookie
      .split('; ')
      .find((row) => row.startsWith('sessionID='))
      ?.split('=')[1];

    if (!sessionID) {
      sessionID = uuidv4();
      document.cookie = `sessionID=${sessionID}; path=/;`;
    }
    return sessionID;
  };

  const handleEmailSubmit = async (
    email: string,
    setError: (msg: string | null) => void,
  ) => {
    console.log('Submitting email:', email);

    const sessionID = getSessionID();

    try {
      await axiosInstance.post('/api/request-otp', {
        email,
        sessionID,
      });

      // Redirect to EmailVerify page with email as a query parameter
      navigate(`/email-verification?email=${encodeURIComponent(email)}`);
    } catch (error) {
      console.error('Error sending OTP:', error);
      setError('An error occurred while sending the OTP. Please try again.');
    }
  };

  return (
    <div className="email-page-container">
      <Wordmark />
      <HamburgerMenu />
      <main>
        {isSubscriber ? (
          <Form
            className="form-container--email"
            buttonLabel="Go To Game Library"
            onSubmit={() => navigate('/game-library')} // Navigate directly to game library
            headerText="Sign Up / Sign In"
            explainerText="You are already signed in and ready to start playing."
          />
        ) : (
          <>
            <Form
              className="form-container--email"
              placeholder="Enter your email"
              buttonLabel="Submit"
              onSubmit={handleEmailSubmit}
              validationType="email"
              headerText="Sign Up / Sign In"
              explainerText="One-time passcode will be emailed to you."
            />
            <div className="terms-container">
              <p>
                You agree to our{' '}
                <a href="/terms" target="_blank" rel="noopener noreferrer">
                  Terms
                </a>{' '}
                by using our service.
              </p>
            </div>
          </>
        )}
      </main>
    </div>
  );
};

export default Email;
