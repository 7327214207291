// src/utils/returnToPage.ts
import { useEffect } from 'react';
import throttle from 'lodash/throttle';

export const usePageVisibility = (onVisible: () => void, delay = 1000) => {
  useEffect(() => {
    const throttledOnVisible = throttle(onVisible, delay, {
      leading: true,
      trailing: false,
    });

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        throttledOnVisible();
      }
    };

    const handleFocus = () => {
      throttledOnVisible();
    };

    const handlePageShow = (event: PageTransitionEvent) => {
      if (event.persisted) {
        throttledOnVisible();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('focus', handleFocus);
    window.addEventListener('pageshow', handlePageShow);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('pageshow', handlePageShow);
      throttledOnVisible.cancel();
    };
  }, [onVisible, delay]);
};
