import React, { ReactNode } from 'react';
import { WebSocketProvider } from './WebSocketContext';
import { UserProvider } from './UserContext';
import { AudioControlProvider } from './AudioControlContext';
import { SpeechProvider } from './SpeechContext';

/**
 * Consolidated GlobalProvider to wrap global contexts.
 */
export const GlobalProvider = ({ children }: { children: ReactNode }) => {
  return (
    <WebSocketProvider>
      <UserProvider>
        <AudioControlProvider>
          <SpeechProvider>{children}</SpeechProvider>
        </AudioControlProvider>
      </UserProvider>
    </WebSocketProvider>
  );
};
