import React, {
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { Player } from '../types';
import {
  fetchPlayerBySession,
  fetchPlayerById,
  fetchRoomPlayers,
} from '../services/playerService';
import { useRoomDetails } from './RoomDetailsContext';
import { useWebSocket } from './WebSocketContext';
import { usePageVisibility } from '../utils/returnToPage';

const PlayerInfoContext = createContext<
  | {
      isMyPlayerTurn: boolean;
      myPlayer: Player | null;
      currentPlayer: Player | null;
      players: Player[];
    }
  | undefined
>(undefined);

interface PlayerInfoProviderProps {
  children: ReactNode;
}

export function PlayerInfoProvider({
  children,
}: PlayerInfoProviderProps): JSX.Element | null {
  const sessionID = getCookie('sessionID');
  const { roomDetails } = useRoomDetails();
  const { joinGameRoom, onPlayerUpdate } = useWebSocket();
  const [myPlayer, setMyPlayer] = useState<Player | null>(null);
  const [currentPlayer, setCurrentPlayer] = useState<Player | null>(null);
  const [players, setPlayers] = useState<Player[]>([]);

  // Destructure and memoize necessary room details
  const { roomCode, currentPlayerTurnId } = useMemo(
    () => ({
      roomCode: roomDetails?.roomCode || '',
      currentPlayerTurnId:
        roomDetails?.gameStructure?.currentPlayerTurnId || '',
    }),
    [roomDetails?.roomCode, roomDetails?.gameStructure?.currentPlayerTurnId],
  );

  // Ref to prevent concurrent fetches for the same room
  const isFetchingRef = useRef<{ [key: string]: boolean }>({});

  // Function to fetch all player-related data
  const fetchPlayerInfo = useCallback(async () => {
    // Check if myPlayer info is required based on URL
    const shouldFetchMyPlayer =
      location.pathname.endsWith('/playing') ||
      location.pathname.endsWith('/player');

    // Fetch myPlayer only if required
    if (shouldFetchMyPlayer && sessionID) {
      try {
        const data = await fetchPlayerBySession(sessionID);
        setMyPlayer(data);
      } catch (error) {
        if (error instanceof Error) {
          console.error('[ERROR] Failed to fetch myPlayer:', error.message);
        } else {
          console.error('[ERROR] Unknown error occurred:', error);
        }
        setMyPlayer(null);
      }
    } else {
      setMyPlayer(null); // Clear myPlayer if it's not needed
    }

    // Fetch currentPlayer
    if (currentPlayerTurnId) {
      try {
        const data = await fetchPlayerById(currentPlayerTurnId);
        setCurrentPlayer(data);
      } catch (error) {
        if (error instanceof Error) {
          console.error(
            '[ERROR] Failed to fetch currentPlayer:',
            error.message,
          );
        } else {
          console.error('[ERROR] Unknown error occurred:', error);
        }
        setCurrentPlayer(null);
      }
    } else {
      setCurrentPlayer(null);
    }

    // Fetch all players using roomCode
    if (roomCode) {
      try {
        // Prevent concurrent fetches for the same room
        if (isFetchingRef.current[roomCode]) {
          console.log('[DEBUG] Fetch already in progress for this room.');
          return;
        }

        isFetchingRef.current[roomCode] = true;

        const playerData = await fetchRoomPlayers(roomCode);
        setPlayers((prev) => {
          if (JSON.stringify(prev) !== JSON.stringify(playerData)) {
            console.debug('[DEBUG] Fetched players:', playerData);
            return playerData;
          }
          return prev;
        });
      } catch (error) {
        if (error instanceof Error) {
          console.error('[ERROR] Error fetching players:', error.message);
        } else {
          console.error('[ERROR] Unknown error occurred:', error);
        }
      } finally {
        isFetchingRef.current[roomCode] = false;
      }
    }
  }, [sessionID, roomCode, currentPlayerTurnId]);

  // Use the custom hook to refresh player info when the page becomes visible
  usePageVisibility(fetchPlayerInfo);

  // When the room code changes, join the room and fetch player info
  useEffect(() => {
    if (roomDetails?.roomCode) {
      joinGameRoom(roomDetails.roomCode); // Join WebSocket room based on roomCode
      fetchPlayerInfo(); // Fetch player info
    }
  }, [roomDetails?.roomCode, joinGameRoom, fetchPlayerInfo]);

  // Listen for player updates from WebSocket and refresh player info
  useEffect(() => {
    const handlePlayerUpdate = async () => {
      await fetchPlayerInfo();
    };

    const cleanupPlayerUpdate = onPlayerUpdate(handlePlayerUpdate);
    return () => cleanupPlayerUpdate();
  }, [onPlayerUpdate, fetchPlayerInfo]);

  // Determine if it's the current user's turn
  const isMyPlayerTurn = myPlayer?._id === currentPlayer?._id;

  // Memoize the context value
  const contextValue = useMemo(
    () => ({
      isMyPlayerTurn,
      myPlayer,
      currentPlayer,
      players,
    }),
    [isMyPlayerTurn, myPlayer, currentPlayer, players], // Dependencies
  );

  return (
    <PlayerInfoContext.Provider value={contextValue}>
      {children}
    </PlayerInfoContext.Provider>
  );
}

// Utility function to retrieve a specific cookie by name
function getCookie(name: string): string | null {
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
  return match ? match[2] : null;
}

// Custom hook to access PlayerInfoContext in other components
export function usePlayerInfoContext() {
  const context = useContext(PlayerInfoContext);
  if (!context) {
    throw new Error(
      'usePlayerInfoContext must be used within a PlayerInfoProvider',
    );
  }
  return context;
}
