import React, { useState } from 'react';
import ChoiceList from '../ChoiceList';
import './HostStyleSelection.css';
import axiosInstance from '../../utils/axiosConfig';
import { hostStyleOptions } from '../../utils/userSettingsOptions';

interface OnboardingProps {
  nextStep: () => void; // Prop to move to the next onboarding step
}

const HostStyleSelection: React.FC<OnboardingProps> = ({ nextStep }) => {
  // State to keep track of the selected choice for UI feedback
  const [selectedChoice, setSelectedChoice] = useState<string | null>(null);

  // Separate choices into enabled and disabled lists
  const choices = hostStyleOptions.map((option) => option.label);
  const disabledChoices = hostStyleOptions
    .filter((option) => option.disabled)
    .map((option) => option.label);

  const handleHostStyleChoice = async (choice: string) => {
    const selectedOption = hostStyleOptions.find(
      (option) => option.label === choice,
    );

    if (!selectedOption) return;

    try {
      // Update the selected choice locally for immediate feedback
      setSelectedChoice(choice);

      // Send selected host style to the backend
      await axiosInstance.post('/api/update-user', {
        $set: { 'userSettings.hostStyle': selectedOption.value }, // Update hostStyle in userSettings
      });

      console.log(`Updated host style to: ${selectedOption.value}`);
      // Call nextStep to move to the next onboarding step
      nextStep();
    } catch (error) {
      console.error('Failed to update host style:', error);
    }
  };

  return (
    <div className="onboard-pick-host-style">
      <div className="form-header-explainer">
        <div className="form-header"> Host Style </div>
        <div className="form-explainer">
          {' '}
          Choose the personality of your game show host
        </div>
      </div>
      <ChoiceList
        choices={choices} // Passes the labels only
        selectedChoice={selectedChoice} // for UI feedback
        onSelectChoice={handleHostStyleChoice}
        disabledChoices={disabledChoices} // Passes only disabled choices
      />
    </div>
  );
};

export default HostStyleSelection;
