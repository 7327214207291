// src/utils/getBaseURL.ts

// Get the base URL for API requests
export const getBaseURL = (): string => {
  const isLocal = window.location.hostname === 'localhost';
  const isNetwork = window.location.hostname.startsWith('192.168');

  if (isLocal) {
    return process.env.REACT_APP_API_BASE_URL_LOCAL || '';
  }

  if (isNetwork) {
    return process.env.REACT_APP_API_BASE_URL_NETWORK || '';
  }

  // For all other URLs use this one
  return process.env.REACT_APP_API_BASE_URL || '';
};

// Get the WebSocket URL based on the base URL
export const getWebSocketURL = (): string => {
  const baseURL = getBaseURL();

  // Ensure the base URL is valid
  if (!baseURL) {
    console.error('Base URL is not defined');
    return '';
  }

  // Replace 'http' with 'ws' or 'https' with 'wss' for WebSocket connection
  if (baseURL.startsWith('https')) {
    return baseURL.replace('https', 'wss');
  } else if (baseURL.startsWith('http')) {
    return baseURL.replace('http', 'ws');
  } else {
    console.error('Base URL is not in a valid HTTP/HTTPS format');
    return '';
  }
};
