import { useEffect, useRef } from 'react';
import { useWebSocket } from '../contexts/WebSocketContext';
import { RoomUpdatePayload } from '../types';
import { useRoomDetails } from '../contexts/RoomDetailsContext';
import { useNavigate } from 'react-router-dom';

// Custom hook to handle end-game actions like resetting game state
export const useHandleEndGame = (
  updateRoom: (payload: RoomUpdatePayload) => Promise<void>,
) => {
  const { emitRedirectReplay, emitRedirectLibrary } = useWebSocket(); // Retrieve both emit functions

  // Function to reset the game state in preparation for a new game or replay
  const resetRoomForNextGame = async (roomCode: string) => {
    if (!roomCode) return;

    const payload: RoomUpdatePayload = {
      roomCode,
      $set: {
        activeGame: false,
        'gameStructure.actions.3.totalScores': [],
        'gameStructure.currentRound': 1,
        'gameStructure.gamePhase': 0,
      },
    };

    try {
      await updateRoom(payload);
      console.log(`Room ${roomCode} reset for the next game`);
    } catch (error) {
      console.error('Failed to reset room for next game:', error);
    }
  };

  const navigate = useNavigate(); // Initialize navigate function

  // Triggers a backend reset for replay purposes
  const resetGameForReplay = async (roomCode: string) => {
    await resetRoomForNextGame(roomCode);
    emitRedirectReplay(roomCode); // Emit replay-specific event
    console.log(`Replay setup complete for room: ${roomCode}`);

    // Directly redirect the host without waiting for the event
    navigate(`/room/${roomCode}`);
  };

  // Prepares the backend for a transition to the game library
  const resetGameForLibrary = async (roomCode: string) => {
    await resetRoomForNextGame(roomCode);
    emitRedirectLibrary(roomCode); // Emit library-specific event
    console.log(`Game state reset for library transition in room: ${roomCode}`);

    // Directly redirect the host without waiting for the event
    navigate('/game-library');
  };

  return { resetGameForReplay, resetGameForLibrary };
};

// Custom hook to listen for end-game events and perform redirects
export const useRedirectOnEndGameEvent = (isPlayingScreen: boolean) => {
  const { roomDetails } = useRoomDetails();
  const { onRedirectReplay, onRedirectLibrary } = useWebSocket();
  const roomCodeRef = useRef(roomDetails?.roomCode);
  const navigate = useNavigate();

  // Update the ref whenever roomDetails?.roomCode changes
  useEffect(() => {
    if (roomDetails?.roomCode) {
      roomCodeRef.current = roomDetails.roomCode;
    }
  }, [roomDetails?.roomCode]);

  useEffect(() => {
    // Handle redirect to replay screen
    const handleRedirectReplay = () => {
      console.log('[DEBUG] Redirecting to replay screen');
      const path = isPlayingScreen
        ? `/room/${roomCodeRef.current}/player` // Player screen
        : `/room/${roomCodeRef.current}`; // Host screen
      navigate(path); // Use navigate for routing
    };

    // Handle redirect to library
    const handleRedirectLibrary = () => {
      console.log('[DEBUG] Redirecting to game library');
      const path = isPlayingScreen
        ? '/' // Player screen
        : '/game-library'; // Host screen
      navigate(path); // Use navigate for routing
    };

    // Register the listeners
    const unsubscribeReplay = onRedirectReplay(handleRedirectReplay);
    const unsubscribeLibrary = onRedirectLibrary(handleRedirectLibrary);

    // Clean up listeners on component unmount
    return () => {
      unsubscribeReplay();
      unsubscribeLibrary();
    };
    // Dependencies are stable due to useCallback in useWebSocket
  }, [onRedirectReplay, onRedirectLibrary, isPlayingScreen, navigate]); // Add navigate as a dependency
};
