import React, { ReactNode } from 'react';
import { RoomDetailsProvider } from './RoomDetailsContext';
import { PlayerInfoProvider } from './PlayerInfoContext';

/**
 * CombinedRoomProviders conditionally wraps room-specific contexts based on roomCode.
 */
interface CombinedRoomProvidersProps {
  children: ReactNode;
  roomCode?: string;
}

export const CombinedRoomProviders = ({
  children,
  roomCode,
}: CombinedRoomProvidersProps) => {
  // If no roomCode is provided, return children directly (no wrapping).
  if (!roomCode) return <>{children}</>;

  // Wrap room-specific providers only when roomCode exists.
  return (
    <RoomDetailsProvider roomCode={roomCode}>
      <PlayerInfoProvider>{children}</PlayerInfoProvider>
    </RoomDetailsProvider>
  );
};
