import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Wordmark from '../components/Wordmark';
import './Terms.css';
import HamburgerMenu from '../components/HamburgerMenu';

const Terms: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        // Get the element's position relative to the viewport
        const elementTop = element.getBoundingClientRect().top;
        // Get the current scroll position
        const offset = 160; // Adjust for your fixed header height
        const scrollPosition = window.scrollY + elementTop - offset;

        // Smoothly scroll to the calculated position
        window.scrollTo({
          top: scrollPosition,
          behavior: 'smooth',
        });
      }
    }
  }, [location.hash]);

  return (
    <div className="terms-page">
      <Wordmark />
      <div className="terms-button">
        <HamburgerMenu />
      </div>

      <div className="terms-and-conditions">
        <div className="terms-header">
          <h1>Terms & Conditions for Airhead Games</h1>
          <p>
            <strong>Last Updated:</strong> December 9, 2024
          </p>
        </div>

        <section>
          <h2>1. Acceptance of Terms</h2>
          <p>
            By accessing or using Airhead Games (“Service”), you agree to be
            bound by these Terms & Conditions (“Terms”). If you do not agree,
            please do not use the Service.
          </p>
        </section>

        <section>
          <h2>2. Operator of the Service</h2>
          <p>
            Airhead Games is operated by an independent software developer
            (“we,” “our,” “us”). For any inquiries, please contact{' '}
            <a href="mailto:poppy@airheadgames.com">poppy@airheadgames.com</a>.
          </p>
        </section>

        <section>
          <h2>3. Eligibility</h2>
          <p>
            You must be at least 13 years old to use the Service. By using the
            Service, you represent that you meet this age requirement.
          </p>
        </section>

        <section>
          <h2>4. User Accounts</h2>
          <ul>
            <li>
              <strong>Account Creation:</strong> You must provide a valid email
              address for account creation and verification.
            </li>
            <li>
              <strong>Account Security:</strong> You are responsible for
              maintaining the confidentiality of your login credentials and for
              all activities that occur under your account.
            </li>
            <li>
              <strong>Account Termination:</strong> We reserve the right to
              suspend or terminate any account at any time, with or without
              notice, for reasons including but not limited to violation of
              these Terms, non-payment, or any behavior deemed abusive or
              harmful to the Service or its users.
            </li>
          </ul>
        </section>

        <section>
          <h2>5. Intellectual Property and Content Ownership</h2>
          <h3>Our Intellectual Property (IP):</h3>
          <ul>
            <li>
              All content generated by the Service’s AI, as well as all
              trademarks, logos, graphics, designs, text, and other materials
              provided by us, are owned solely by Airhead Games.
            </li>
            <li>
              Users are granted a limited, revocable, non-exclusive license to
              access and use AI-generated content for personal, non-commercial
              purposes only.
            </li>
            <li>
              Any reproduction, modification, distribution, or commercial use of
              AI-generated content without explicit written permission from
              Airhead Games is strictly prohibited.
            </li>
          </ul>
          <h3>User-Provided Content:</h3>
          <ul>
            <li>
              By inputting any text, data, or other materials (“User Content”)
              into the Service, you grant Airhead Games a worldwide,
              non-exclusive, royalty-free, irrevocable, sublicensable, and
              transferable license to use, reproduce, modify, create derivative
              works from, display, and perform your User Content solely in
              connection with operating and improving the Service.
            </li>
            <li>
              You represent and warrant that you have all necessary rights to
              grant this license and that your User Content does not infringe
              upon any third-party rights.
            </li>
            <li>
              You retain ownership of any User Content you provide, subject to
              the license granted to us.
            </li>
          </ul>
        </section>

        <section>
          <h2>6. AI-Generated Content Disclaimer</h2>
          <p>
            The Service uses AI to generate content. While we strive to align
            content with user preferences (e.g., general audiences,
            family-friendly audiences, mature audiences), we cannot fully
            control the AI output. Content may occasionally be inappropriate or
            unexpected. By using the Service, you acknowledge and accept this
            risk. We do not guarantee the AI-generated content will meet your
            expectations, be accurate, or be free of objectionable elements.
          </p>
        </section>

        <section>
          <h2>7. Payments and Subscriptions</h2>
          <h3>Subscription Fees:</h3>
          <p>
            Access to certain features may require a paid subscription. Fees
            will be processed through Stripe, and by subscribing you agree to
            Stripe’s{' '}
            <a
              href="https://stripe.com/legal"
              target="_blank"
              rel="noopener noreferrer"
            >
              payment processing terms
            </a>
            .
          </p>
          <h3>Refunds:</h3>
          <ul>
            <li>
              Refunds may be issued at our discretion in certain circumstances,
              such as technical issues preventing access to the Service.
            </li>
            <li>
              To request a refund, contact us at{' '}
              <a href="mailto:poppy@airheadgames.com">poppy@airheadgames.com</a>
              . We reserve the right to determine eligibility for any refund
              requests.
            </li>
          </ul>
          <h3>Cancellation Policy:</h3>
          <p>
            You may cancel your subscription at any time. Cancellation will take
            effect at the end of the current billing cycle, and you will not be
            charged for subsequent periods. Subscriptions for Airhead Games can
            be managed via Settings (in Game Library) or directly visiting{' '}
            <a
              href="https://billing.stripe.com/p/login/7sI29i8XE5vwd9ufYY"
              target="_blank"
              rel="noopener noreferrer"
            >
              Stripe Billing Portal
            </a>
            .
          </p>
        </section>

        <section>
          <h2 id="privacy">8. Privacy and Data Use</h2>
          <h3>Data Collected:</h3>
          <p>
            We collect only your email address for account-related purposes.
          </p>
          <h3>Data Use:</h3>
          <ul>
            <li>
              <strong>Account Management:</strong> Your email is used for
              account creation, verification, and essential service-related
              communications.
            </li>
            <li>
              <strong>Future Marketing:</strong> We may use your email for
              marketing purposes in the future, for which you will have the
              option to opt-out.
            </li>
          </ul>
          <h3>Data Protection:</h3>
          <ul>
            <li>
              <strong>GDPR Compliance:</strong> If you are located in the EU,
              you have the right to access, rectify, and request deletion of
              your personal data. You can exercise these rights by contacting us
              at{' '}
              <a href="mailto:poppy@airheadgames.com">poppy@airheadgames.com</a>
              .
            </li>
            <li>
              <strong>CCPA Compliance:</strong> California residents have the
              right to request access to and deletion of their personal data,
              and to opt-out of the sale of their personal data. To exercise
              these rights, please contact us at{' '}
              <a href="mailto:poppy@airheadgames.com">poppy@airheadgames.com</a>
              .
            </li>
          </ul>
          <h3>Data Deletion:</h3>
          <p>
            You may request deletion of your account and associated email
            address at any time by contacting us at{' '}
            <a href="mailto:poppy@airheadgames.com">poppy@airheadgames.com</a>.
            We will comply with such requests in accordance with applicable
            laws.
          </p>
          <h3>No Separate Privacy Policy:</h3>
          <p>
            By using the Service, you consent to the collection and use of your
            data as described in these Terms. This constitutes our Privacy
            Policy.
          </p>
        </section>

        <section>
          <h2>9. Third-Party Tools and Services</h2>
          <p>
            The Service integrates third-party tools and services. By using the
            Service, you acknowledge that these third parties may have their own
            terms and policies. We are not responsible for their content,
            accuracy, or availability. You agree to comply with any additional
            terms required by these third parties.
          </p>
        </section>

        <section>
          <h2>10. Disclaimers and Limitation of Liability</h2>
          <ul>
            <li>
              <strong>“As Is” Basis:</strong> The Service is provided on an “as
              is” and “as available” basis without warranties of any kind,
              whether express or implied.
            </li>
            <li>
              <strong>No Warranty for Uptime or Performance:</strong> We do not
              guarantee uninterrupted access, security, or error-free
              performance.
            </li>
            <li>
              <strong>Limitation of Liability:</strong> To the maximum extent
              permitted by law, we shall not be liable for any indirect,
              incidental, consequential, or special damages arising out of or in
              connection with your use of the Service, even if we have been
              advised of the possibility of such damages.
            </li>
            <li>
              <strong>Personal Liability:</strong> As an independent developer,
              our total liability for any claims related to the Service shall be
              limited to the total amount you have paid for the Service in the 6
              months preceding the claim, up to a maximum of $50.
            </li>
          </ul>
        </section>

        <section>
          <h2>11. Governing Law and Dispute Resolution</h2>
          <h3>Governing Law:</h3>
          <p>
            These Terms shall be governed by and construed in accordance with
            the laws of the State of California, USA, without regard to its
            conflict of laws principles.
          </p>
          <h3>Jurisdiction:</h3>
          <p>
            You agree that any disputes arising under these Terms shall be
            resolved in the state or federal courts located in San Francisco,
            California, USA.
          </p>
          <h3>Dispute Resolution:</h3>
          <ul>
            <li>
              Before initiating any legal proceedings, you agree to attempt to
              resolve the dispute informally by contacting us at{' '}
              <a href="mailto:poppy@airheadgames.com">poppy@airheadgames.com</a>
              .
            </li>
            <li>
              If the dispute is not resolved within 30 days, either party may
              then proceed with formal legal remedies.
            </li>
            <li>
              Alternatively, both parties agree to consider mediation or
              arbitration as a means to resolve disputes before seeking judicial
              intervention.
            </li>
          </ul>
        </section>

        <section>
          <h2>12. Changes to These Terms</h2>
          <p>
            We reserve the right to modify these Terms at any time. If we make
            significant changes, we will notify you by email or by posting a
            notice on the Service. Your continued use of the Service after the
            changes become effective will signify your acceptance of the revised
            Terms.
          </p>
        </section>

        <section>
          <h2>13. Entire Agreement</h2>
          <p>
            These Terms constitute the entire agreement between you and us
            regarding the use of the Service and supersede any prior agreements.
          </p>
        </section>

        <section>
          <h2>14. Severability and Waiver</h2>
          <p>
            If any provision of these Terms is deemed invalid or unenforceable,
            the remaining provisions will remain in full force and effect.
            Failure to enforce any right or provision does not constitute a
            waiver of such right or provision.
          </p>
        </section>

        <div className="terms-spacer"></div>
      </div>
    </div>
  );
};

export default Terms;
