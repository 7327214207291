// src/components/Button.tsx
import React from 'react';
import './Button.css';

interface ButtonProps {
  onClick: () => void;
  label: string | React.ReactNode;
  disabledButton?: boolean;
  variant?: 'default' | 'success' | 'transparent';
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  label,
  disabledButton = false,
  variant = 'default',
}) => {
  const buttonClass = `button ${
    variant === 'success'
      ? 'button-success'
      : variant === 'transparent'
        ? 'button-transparent'
        : ''
  }`;

  return (
    <button className={buttonClass} onClick={onClick} disabled={disabledButton}>
      {label}
    </button>
  );
};

export default Button;
