import React, { useEffect, useRef } from 'react';

interface AvatarProps {
  color: string;
  size: number; // Avatar size
}

const Avatar: React.FC<AvatarProps> = ({ color, size }) => {
  const svgRef = useRef<HTMLObjectElement>(null);

  useEffect(() => {
    const applyColorToSVG = () => {
      const svgDoc = svgRef.current?.contentDocument;
      if (svgDoc) {
        const paths = svgDoc.querySelectorAll('path');
        paths.forEach((path) => {
          path.setAttribute('fill', color);
        });
        console.log('Avatar color applied:', color); // Log applied color
      } else {
        console.error('Failed to access or modify the SVG document.');
      }
    };

    const handleSVGLoad = () => {
      console.log('SVG loaded');
      applyColorToSVG();
    };

    const currentSVGRef = svgRef.current;
    if (currentSVGRef) {
      currentSVGRef.addEventListener('load', handleSVGLoad);
      // Apply color immediately in case SVG is already loaded
      applyColorToSVG();
    }

    return () => {
      if (currentSVGRef) {
        currentSVGRef.removeEventListener('load', handleSVGLoad);
      }
    };
  }, [color]);

  return (
    <div className="avatar-container" style={{ width: size, height: size }}>
      <object
        ref={svgRef}
        type="image/svg+xml"
        data="/assets/avatar-normal.svg"
        style={{ width: '100%', height: '100%' }}
        aria-label="Avatar"
      />
    </div>
  );
};

export default Avatar;
