import React from 'react';
import { useLocation } from 'react-router-dom';
import './EmailVerify.css';
import Form from '../components/Form';
import Wordmark from '../components/Wordmark';
import axiosInstance from '../utils/axiosConfig';
import axios from 'axios';
import HamburgerMenu from '../components/HamburgerMenu';

interface ErrorResponse {
  message: string;
}

const EmailVerify: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');

  const handleOTPSubmit = async (
    otp: string,
    setError: (msg: string | null) => void,
  ) => {
    setError(null);

    if (!otp) {
      setError('Please enter code.');
      return;
    }

    try {
      // Verify OTP and get JWT
      const response = await axiosInstance.post('/api/verify-otp', {
        email,
        otp,
      });

      if (response.status === 200) {
        // JWT is stored in an HTTP-only cookie; no need to store it in localStorage
        // Use the JWT cookie to get user details
        const userResponse = await axiosInstance.get('/api/me');

        const isSubscriber = userResponse.data.subscriber;

        // Redirect based on subscriber status
        window.location.href = isSubscriber ? '/game-library' : '/subscribe';
      }
    } catch (err) {
      // Handle Axios error type
      if (axios.isAxiosError(err)) {
        const errorData = err.response?.data as ErrorResponse;

        if (errorData?.message === 'OTP expired') {
          setError('Code has expired. Go back to try again.');
        } else if (errorData?.message === 'Invalid OTP') {
          setError('Invalid code. Please try again.');
        } else {
          setError('An error occurred. Please try again.');
        }
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
    }
  };

  return (
    <div className="otp-page-container">
      <Wordmark />
      <HamburgerMenu />
      <main>
        <Form
          className="form-container--emailverify"
          placeholder="Enter code"
          buttonLabel="Submit"
          onSubmit={handleOTPSubmit}
          validationType="number"
          headerText="Enter Passcode"
          explainerText="Check your email for the code"
          customContent={
            email ? <div>{email}</div> : <div>No email provided</div>
          }
        />
      </main>
    </div>
  );
};

export default EmailVerify;
