import React, { useState } from 'react';
import ChoiceList from '../ChoiceList';
import './AccentSelection.css';
import axiosInstance from '../../utils/axiosConfig';
import { useUserContext } from '../../contexts/UserContext';
import { languageOptions } from '../../utils/userSettingsOptions';

interface OnboardingProps {
  nextStep: () => void; // Prop to move to the next onboarding step
}

const AccentSelection: React.FC<OnboardingProps> = ({ nextStep }) => {
  // State to keep track of the selected choice for UI feedback
  const [selectedChoice, setSelectedChoice] = useState<string | null>(null);

  const { user, setUser } = useUserContext();

  // Get the user's selected language from userSettings
  const selectedLanguage = user?.userSettings.language;

  // Find language data from languageOptions based on selected language
  const selectedLanguageData = languageOptions.find(
    (option) => option.value === selectedLanguage,
  );

  // If the selected language has no associated accents, show a message
  if (!selectedLanguageData || selectedLanguageData.accents.length === 0) {
    return <div>No accents available for this language.</div>;
  }

  const accentChoices = selectedLanguageData.accents.map(
    (accent) => accent.label,
  );

  const handleAccentChoice = async (choice: string) => {
    const selectedAccent = selectedLanguageData.accents.find(
      (accent) => accent.label === choice,
    );

    if (!selectedAccent) return;

    try {
      // Update the selected choice locally for immediate feedback
      setSelectedChoice(choice);

      // Send the selected accent to the backend and update the user context
      await axiosInstance.post('/api/update-user', {
        $set: { 'userSettings.hostAccent': selectedAccent.value },
      });

      // Update hostAccent in UserContext
      setUser((prevUser) =>
        prevUser
          ? {
              ...prevUser,
              userSettings: {
                ...prevUser.userSettings,
                hostAccent: selectedAccent.value,
              },
            }
          : null,
      );

      console.log(`Updated accent to: ${selectedAccent.value}`);
      // Call nextStep to move to the next onboarding step
      nextStep();
    } catch (error) {
      console.error('Failed to update accent setting:', error);
    }
  };

  return (
    <div className="onboard-pick-accent">
      <div className="form-header-explainer">
        <div className="form-header"> Accent </div>
        <div className="form-explainer">
          {' '}
          Choose an accent for your game show host{' '}
        </div>
      </div>
      <ChoiceList
        choices={accentChoices} // Pass only the labels of available accents
        selectedChoice={selectedChoice} // for UI feedback
        onSelectChoice={handleAccentChoice}
      />
    </div>
  );
};

export default AccentSelection;
