// components/ProgressBar.tsx
import React from 'react';
import { Circle } from 'rc-progress';
import './ProgressBar.css';

interface ProgressBarProps {
  progress: number; // Progress percentage (0 to 100)
  className?: string; // Optional for styling
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progress, className }) => (
  <div className={`progress-bar-container ${className || ''}`}>
    <div className="progress-bar-wrapper">
      <Circle
        percent={progress}
        strokeWidth={8}
        strokeColor="#00ff2f"
        trailColor="#828282"
      />
      {progress === 100 && <div className="checkmark">✓</div>}
    </div>
  </div>
);

export default ProgressBar;
