import React, { useState } from 'react';
import ChoiceList from '../ChoiceList';
import './LanguageSelection.css';
import axiosInstance from '../../utils/axiosConfig';
import { languageOptions } from '../../utils/userSettingsOptions';

interface OnboardingProps {
  nextStep: () => void; // Prop to move to the next onboarding step
}

const LanguageSelection: React.FC<OnboardingProps> = ({ nextStep }) => {
  // State to keep track of the selected choice for UI feedback
  const [selectedChoice, setSelectedChoice] = useState<string | null>(null);

  // Separate choices into enabled and disabled lists
  const choices = languageOptions.map((option) => option.label);
  const disabledChoices = languageOptions
    .filter((option) => option.disabled)
    .map((option) => option.label);

  const handleLanguageChoice = async (choice: string) => {
    const selectedOption = languageOptions.find(
      (option) => option.label === choice,
    );

    if (!selectedOption) return;

    try {
      // Update the selected choice locally for immediate feedback
      setSelectedChoice(choice);

      // Send selected language to the backend
      await axiosInstance.post('/api/update-user', {
        $set: { 'userSettings.language': selectedOption.value }, // Update language setting in userSettings
      });

      console.log(`Updated language to: ${selectedOption.value}`);

      // Call nextStep to move to the next onboarding step
      nextStep();
    } catch (error) {
      console.error('Failed to update language setting:', error);
    }
  };

  return (
    <div className="onboard-pick-language">
      <div className="form-header-explainer">
        <div className="form-header"> Language </div>
        <div className="form-explainer"> Choose your preferred language</div>
      </div>
      <ChoiceList
        choices={choices} // Passes the labels only
        selectedChoice={selectedChoice} // for UI feedback
        onSelectChoice={handleLanguageChoice}
        disabledChoices={disabledChoices} // Passes only disabled choices
      />
    </div>
  );
};

export default LanguageSelection;
