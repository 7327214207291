import React, { useEffect, useState } from 'react';
import ProgressBar from '../components/ProgressBar';
import LanguageSelection from '../components/Onboarding/LanguageSelection';
import AccentSelection from '../components/Onboarding/AccentSelection';
import HostStyleSelection from '../components/Onboarding/HostStyleSelection';
import ContentStyleSelection from '../components/Onboarding/ContentStyleSelection';
import StripePricingTable from '../components/Onboarding/StripePricingTable';
import StripeCompleted from '../components/Onboarding/StripeCompleted';
import Wordmark from '../components/Wordmark';
import { useUserContext } from '../contexts/UserContext';
import './Subscribe.css';

const Subscribe: React.FC = () => {
  // Access the user settings from context using the custom hook
  const { user } = useUserContext();

  // Handle refresh logic if user navigates back from Stripe Checkout
  useEffect(() => {
    if (document.referrer.includes('checkout.stripe.com')) {
      // Reload the page to reset state
      window.location.reload();
    }
  }, []);

  // Extract `onboardingSet` and subscriber status from user settings
  const onboardingSet = user?.userSettings?.onboardingSet ?? false;
  const subscriberSet = user?.subscriber ?? false;

  // Set initial onboarding step based on `onboardingSet`
  const [onboardingStep, setOnboardingStep] = useState(() => {
    if (subscriberSet) return 6; // Skip to step 6 if subscriberSet is true
    return onboardingSet ? 5 : 1; // Default logic for onboardingSet
  });

  // Total number of steps in the onboarding flow
  const totalSteps = 6;

  // Define the component mapping for each onboarding step
  const componentMap = {
    1: LanguageSelection,
    2: AccentSelection,
    3: HostStyleSelection,
    4: ContentStyleSelection,
    5: StripePricingTable,
    6: StripeCompleted,
  } as const;

  // Get the current component based on onboarding step
  const CurrentComponent =
    componentMap[onboardingStep as keyof typeof componentMap];

  // Function to advance to the next step
  const nextStep = () => {
    if (onboardingStep < totalSteps) {
      setOnboardingStep(onboardingStep + 1); // Move to the next step
    }
  };

  return (
    <div className="subscribe">
      <Wordmark />
      <div className="onboarding-progress-container">
        <span className="onboarding-label">Onboarding</span>
        <ProgressBar
          progress={(onboardingStep / totalSteps) * 100}
          className="onboarding"
        />
      </div>
      <div>
        {/* Render the current onboarding component and pass down nextStep */}
        <CurrentComponent nextStep={nextStep} />
      </div>
    </div>
  );
};

export default Subscribe;
