// ProtectedRoutes.tsx
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useUserContext } from '../../contexts/UserContext';

// Route protection for authenticated users
export const AuthenticatedRoute: React.FC = () => {
  const { user, loading } = useUserContext();

  // If user data is still loading, display a loading message
  if (loading) return <div>Loading...</div>;

  // If the user is authenticated, render the child route components (Outlet)
  // Otherwise, redirect to the login page ("/email")
  return user ? <Outlet /> : <Navigate to="/email" />;
};

// Route protection for subscribed users (requires authentication + subscription)
export const SubscriptionRoute: React.FC = () => {
  const { user, loading } = useUserContext();

  // If user data is still loading, display a loading message
  if (loading) return <div>Loading...</div>;

  // If the user is authenticated and a subscriber, render the child route components (Outlet)
  // Otherwise, redirect to the subscription page ("/subscribe")
  return user && user.subscriber ? <Outlet /> : <Navigate to="/subscribe" />;
};
