import React, { useContext } from 'react';
import axiosInstance from '../utils/axiosConfig';
import './Home.css';
import Form from '../components/Form';
import { UserContext } from '../contexts/UserContext';
import HamburgerMenu from '../components/HamburgerMenu';

const Home: React.FC = () => {
  const userContext = useContext(UserContext);

  if (!userContext) {
    // Handle the case where UserContext is not available
    return null; // Or display an error message
  }

  const { user } = userContext;

  // Use 'isSubscriber' for better readability
  const isSubscriber = user?.subscriber ?? false;

  // Use 'isSubscriber' in your component logic
  const transparentButtonLabel = user
    ? isSubscriber
      ? 'Go To Game Library'
      : 'Finish Onboarding'
    : 'Sign Up / Sign In';

  // Function to validate room code
  const validateRoomCode = async (
    code: string,
    setError: (msg: string | null) => void,
  ) => {
    if (!code) {
      console.warn('No valid room code found, redirecting to homepage...');
      setError('Please enter a room code.'); // Set error message
      return;
    }

    try {
      const response = await axiosInstance.get(
        `/api/validate-room?roomCode=${code}`,
      );
      if (!response.data.valid) {
        console.warn('Invalid room code, displaying error message...');
        setError('Invalid room code. Please try again.'); // Set error message
      } else {
        console.log('Room code is valid, redirecting to player page...');
        window.location.replace(`/room/${code}/player`);
      }
    } catch (error) {
      console.error('Error validating room code:', error);
      setError('An error occurred while validating the room code.'); // Set error message for network error
    }
  };

  const handleJoinRoom = async (
    roomCode: string,
    setError: (msg: string | null) => void,
  ) => {
    console.log('Attempting to join room with code:', roomCode);
    await validateRoomCode(roomCode, setError); // Pass setError to the validation function
  };

  const handleTransparentButtonClick = () => {
    if (user) {
      window.location.href = user.subscriber ? '/game-library' : '/subscribe';
    } else {
      window.location.href = '/email'; // Redirect to sign up if not logged in
    }
  };

  return (
    <div className="home-container">
      <HamburgerMenu />
      <div className="logo-container">
        <img
          src="/assets/logo_stacked.png"
          alt="Airhead Games Logo"
          className="logo"
        />
      </div>
      <Form
        className="form-container--home"
        placeholder="Enter Code"
        buttonLabel="Join Room"
        onSubmit={handleJoinRoom}
        validationType="number"
        transparentButtonLabel={transparentButtonLabel}
        onTransparentButtonClick={handleTransparentButtonClick}
      />
    </div>
  );
};

export default Home;
