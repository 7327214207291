import React from 'react';
import './StripePricingTable.css';
import { useUserContext } from '../../contexts/UserContext';

const StripePricingTable: React.FC = () => {
  const { user } = useUserContext(); // Call the hook and destructure `user` object
  const userId = user?._id; // Extract `_id` safely
  const userEmail = user?.email; // Extract email safely

  // Fetch the publishable key from environment variables
  const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
  console.log('Publishable Key:', publishableKey); // This should show your key if loaded correctly
  const pricingTableId = process.env.REACT_APP_STRIPE_PRICING_TABLE_ID;
  console.log('Pricing Table ID:', pricingTableId);

  if (!publishableKey || !pricingTableId) {
    console.error(
      'Stripe publishable key is not defined. Please check your environment configuration.',
    );
    return null; // Render nothing if the key is missing
  }

  return (
    <div className="onboard-pricing">
      <div className="stripe-wrapper">
        {/* Embed Stripe Pricing Table */}
        <stripe-pricing-table
          pricing-table-id={pricingTableId}
          publishable-key={publishableKey}
          client-reference-id={userId}
          customer-email={userEmail}
        ></stripe-pricing-table>
      </div>
    </div>
  );
};

export default StripePricingTable;
